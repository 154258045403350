import { Box, Skeleton, Typography } from "@mui/material";
import { primaryColors } from "../../../../helpers/customColors";
import { fontWeightVariations } from "../../../../helpers/customFont";
import { GoogleReviewsInsightsCards } from "./GoogleReviewsInsightsCards";
import { TripadvisorInsightsCards } from "./TripadvisorInsightsCards";
import { GoogleBusinessProfileInsightsCards } from "./GoogleBusinessProfileInsightsCards";
import { useGetReputationOverviewCards } from "../../api/reputation-dashboard";
import { useContext } from "react";
import { AuthenticationContext } from "../../../login/contexts/AuthenticationContext";
import { PROFILE_CATEGORY_IDS } from "../../../../helpers/constants";

export const PlatformsInsightsCards = ({
  startDate,
  endDate,
  governorates,
  facilityTypes,
  branches,
}) => {
  const { user } = useContext(AuthenticationContext);
  const {
    data: overviewCardsServerResponse,
    isFetching: isFetchingOverviewCards,
  } = useGetReputationOverviewCards({
    params: {
      startDate,
      endDate,
      governorates,
      facilityTypes,
      branches,
    },
    config: { staleTime: 120000 },
  });

  const profileCategoryID = user.profile["category"]?.id;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      gap="20px"
    >
      {/* Google Reviews */}
      <GoogleReviewsInsightsCards
        startDate={startDate}
        endDate={endDate}
        isFetching={isFetchingOverviewCards}
        googleReviewsCardsServerResponse={
          overviewCardsServerResponse?.data.google_reviews_insights
        }
      />
      {/* Google Business Profile*/}
      <GoogleBusinessProfileInsightsCards
        startDate={startDate}
        endDate={endDate}
        branches={branches}
      />
      {/* Tripadvisor Reviews */}
      {profileCategoryID === PROFILE_CATEGORY_IDS.FoodAndBeverage && (
        <TripadvisorInsightsCards
          startDate={startDate}
          endDate={endDate}
          isFetching={isFetchingOverviewCards}
          tripadvisorCardsServerResponse={
            overviewCardsServerResponse?.data.tripadvisor_reviews_insights
          }
        />
      )}
    </Box>
  );
};
export const SkeletonForRawNumbersCards = () => {
  return (
    <>
      <Skeleton
        variant="rectangular"
        width="24%"
        height={188}
        sx={{ borderRadius: "8px" }}
      />
      <Skeleton
        variant="rectangular"
        width="24%"
        height={188}
        sx={{ borderRadius: "8px" }}
      />
      <Skeleton
        variant="rectangular"
        width="24%"
        height={188}
        sx={{ borderRadius: "8px" }}
      />
      <Skeleton
        variant="rectangular"
        width="24%"
        height={188}
        sx={{ borderRadius: "8px" }}
      />
    </>
  );
};

export const PlatformIconCard = ({ platformName, platformIcon }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
      alignItems="center"
      width="125px"
      height="187px"
      borderRadius="8px"
      boxShadow={2}
    >
      <Box component="img" src={platformIcon} alt="Google Icon" width="77px" />
      <Typography
        fontFamily="Nunito"
        fontWeight={fontWeightVariations.semiBold}
        variant="text-lg"
        color={primaryColors.base.black}
        textAlign="center"
      >
        {platformName}
      </Typography>
    </Box>
  );
};

import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

const getAllGovernorates = () => {
//   const queryData = {
//     params: {
//       no_pagination: true,
//     },
//   };
  const requestURL = `${API_URL}/tags/governorates`;

  return melior.get(requestURL);
};

export const useGetAllGovernorates = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["all-governorates"],
    queryFn: () => getAllGovernorates(),
    keepPreviousData: true,
  });
};
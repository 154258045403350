import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

const getAllBranchesWithoutPagination = ({ governorates, facilityTypes }) => {
  const queryData = {
    params: {
      no_pagination: true,
      ...(governorates?.length > 0
        ? { governorates: governorates?.join(",") }
        : {}),
      ...(facilityTypes?.length > 0
        ? { facility_types: facilityTypes?.join(",") }
        : {}),
    },
  };
  const requestURL = `${API_URL}/branches`;

  return melior.get(requestURL, queryData);
};

export const useGetAllBranchesWithoutPagination = ({ config, params = {governorates: [], facilityTypes: []} } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["all-branches-without-pagination", params],
    queryFn: () => getAllBranchesWithoutPagination(params),
    keepPreviousData: true,
  });
};

import {
  Box,
  Button,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { GenericAccordion } from "../../../reusable/components/GenericAccordion";
import { primaryColors } from "../../../helpers/customColors";
import { fontWeightVariations } from "../../../helpers/customFont";
import { useContext, useMemo, useReducer } from "react";
import { useDropzone } from "react-dropzone";
import { CloudUpload, FileDownloadDone, Warning } from "@mui/icons-material";
import {
  NOTIFICATIONS_HORIZONTAL_POSITION,
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATION_WIDTH,
} from "../../../contexts/NotificationContext";
import readXlsxFile from "read-excel-file";
import { NotificationContext } from "../../../contexts/NotificationContext";
import {
  SURVEY_TYPES,
  acceptStyle,
  baseStyle,
  focusedStyle,
  rejectStyle,
} from "./sharedVariables";
import { InvalidPhoneNumbersDialog } from "./InvalidPhoneNumbersDialog";
import { useSendBulkLinks } from "../api/sendBulkLinks";
import { useEffect } from "react";

export const GenerateBulkLinksAccordion = ({
  disabled,
  selectedBranchData,
  selectedDivisionData,
  selectedTemplateData,
}) => {
  const { fireNotification, closeNotification, notificationState } =
    useContext(NotificationContext);
  const sendBulkLinksMutation = useSendBulkLinks();

  // Start: Reducer State Management
  const [stateOfBulkLinks, dispatchAction] = useReducer(
    bulkLinksReducer,
    bulkLinksDefaultState
  );

  function updateSelectedSurveyTypeHandler(updatedSurveyType) {
    dispatchAction({
      type: bulkLinksDispatchActions["UPDATE-SELECTED-SURVEY-TYPE"],
      payload: { surveyType: updatedSurveyType },
    });
  }

  function updateImportedPhoneNumbersHandler({
    extractedValidPhoneNumbers,
    extractedInvalidPhoneNumbers,
  }) {
    dispatchAction({
      type: bulkLinksDispatchActions["UPDATE-IMPORTED-PHONE-NUMBERS"],
      payload: {
        importedValidPhoneNumbers: extractedValidPhoneNumbers,
        importedInvalidPhoneNumbers: extractedInvalidPhoneNumbers,
      },
    });
  }

  function updateEmailHandler(updatedEmail) {
    dispatchAction({
      type: bulkLinksDispatchActions["UPDATE-EMAIL"],
      payload: { email: updatedEmail },
    });
  }
  // End: Reducer State Management

  function submitValidNumbersForLinkGeneration() {
    sendBulkLinksMutation.mutateAsync({
      branchID: selectedBranchData.id,
      divisionID: selectedDivisionData.id,
      templateID: selectedTemplateData.id,
      validPhoneNumbers: stateOfBulkLinks.importedValidPhoneNumbers,
      type: stateOfBulkLinks.surveyType,
      email: stateOfBulkLinks.email,
    });
  }

  // Start: React Dropzone
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".csv",
          ".xlsx",
        ],
        "application/vnd.ms-excel": [".csv", ".xlsx"],
      },
      onDropRejected: function () {
        fireNotification({
          title: "Error in Uploaded Phone Numbers Sheet",
          description: "Invalid File Type (Must be .csv or .xlsx)",
          type: NOTIFICATIONS_TYPE.error,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
          horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
          target: NOTIFICATIONS_TARGET.admin,
          width: NOTIFICATION_WIDTH.small,
        });
      },
      onDropAccepted: function (uploadedFiles) {
        try {
          readXlsxFile(uploadedFiles[0])
            .then((currSheet) => {
              let columnNames = currSheet[0];
              let index = columnNames.indexOf(
                columnNames.find(
                  (rowName) =>
                    rowName.toLowerCase().includes("phone") ||
                    rowName.toLowerCase().includes("mobile")
                )
              );

              if (index === -1) {
                console.error("Column for phone numbers not found");

                fireNotification({
                  title: "Error in Uploaded Phone Numbers Sheet",
                  description:
                    "Couldn't find a column labeled (Phone Number or Mobile Number)",
                  type: NOTIFICATIONS_TYPE.error,
                  verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
                  horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
                  target: NOTIFICATIONS_TARGET.admin,
                  width: NOTIFICATION_WIDTH.small,
                });
              } else {
                let extractedInvalidPhoneNumbers = [];
                let phoneNumbersMissingZero = [];
                // let totalPhoneNumberRecords = 0;

                const extractedValidPhoneNumbers = currSheet.reduce(
                  (accumlator, currRow) => {
                    const currentNumber = currRow[index]?.toString();
                    if (
                      !!currentNumber &&
                      currentNumber?.trim().length &&
                      currentNumber !== columnNames[index]
                    ) {
                      //   totalPhoneNumberRecords += 1;
                      if (
                        (currentNumber.length === 11 &&
                          currentNumber[0] === "0") ||
                        (currentNumber.length === 12 &&
                          currentNumber.substring(0, 2) === "20") ||
                        (currentNumber.length === 13 &&
                          currentNumber.substring(0, 3) === "+20")
                      ) {
                        accumlator.push(currentNumber);
                      } else {
                        if (
                          currentNumber.length === 10 &&
                          currentNumber[0] === "1"
                        ) {
                          phoneNumbersMissingZero.push(currentNumber);
                        } else {
                          extractedInvalidPhoneNumbers.push(currentNumber);
                        }
                      }
                    }
                    return accumlator;
                  },
                  []
                );

                // Don't update the Phone Numbers and notify the user in case none was found.
                if (
                  extractedValidPhoneNumbers.length === 0 &&
                  extractedInvalidPhoneNumbers.length === 0
                ) {
                  fireNotification({
                    title: "Error in Uploaded Phone Numbers Sheet",
                    description:
                      "No Phone Numbers found in the imported Excel Sheet (Add at least 1)",
                    type: NOTIFICATIONS_TYPE.error,
                    verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
                    horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
                    target: NOTIFICATIONS_TARGET.admin,
                    width: NOTIFICATION_WIDTH.small,
                  });
                }

                // Update the Phone Numbers state in case any of the valid or invalid numbers found.
                if (
                  extractedValidPhoneNumbers.length > 0 ||
                  extractedInvalidPhoneNumbers.length > 0
                ) {
                  updateImportedPhoneNumbersHandler({
                    extractedValidPhoneNumbers: extractedValidPhoneNumbers,
                    extractedInvalidPhoneNumbers: extractedInvalidPhoneNumbers,
                  });
                }
              }
            })
            .catch((error) =>
              console.error("Failed to extract Phone Numbers", error)
            );
        } catch (exception) {
          console.error(
            "Error in try block for extracting Phone Numbers",
            exception
          );
        }
      },
      disabled,
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  // End: React Dropzone

  const readyToSendBulkLinks =
    stateOfBulkLinks.surveyType !== undefined &&
    stateOfBulkLinks.importedValidPhoneNumbers !== undefined &&
    stateOfBulkLinks.importedValidPhoneNumbers.length > 0 &&
    stateOfBulkLinks.emailIsValid;

  // Start: Dynamic Content for Phone Numbers Extracting Excel Sheet Import section
  let iconForPhoneNumbersExcelSheetSection = undefined;
  let helperContentForPhoneNumbersExcelSheetSection = undefined;

  if (stateOfBulkLinks.importedValidPhoneNumbers?.length >= 0) {
    // It means there's a file that was imported successfully
    iconForPhoneNumbersExcelSheetSection = (
      <FileDownloadDone
        sx={{
          width: "20px",
          height: "20px",
          mb: 2,
          p: 1,
          borderRadius: "50%",
          bgcolor: primaryColors.success[50],
          color: primaryColors.success[600],
        }}
      />
    );
    helperContentForPhoneNumbersExcelSheetSection = (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="text-md" color={primaryColors.success[600]}>
          Phone Numbers Excel Sheet Imported Successfully
        </Typography>
        <Typography cariant="text-xs" color={primaryColors.gray[500]}>
          Valid Phone Numbers Count:{" "}
          <span>{stateOfBulkLinks.importedValidPhoneNumbers?.length}</span>
        </Typography>
        <Box display="flex" alignItems="center">
          <InvalidPhoneNumbersDialog
            invalidPhoneNumbers={stateOfBulkLinks.importedInvalidPhoneNumbers}
            triggerButtonLabel={`Invalid Phone Numbers Count: ${stateOfBulkLinks.importedInvalidPhoneNumbers?.length}`}
            submitValidNumbers={submitValidNumbersForLinkGeneration}
            isSubmittingValidNumbers={sendBulkLinksMutation.isLoading}
          />
        </Box>
      </Box>
    );
  } else {
    iconForPhoneNumbersExcelSheetSection = (
      <CloudUpload
        sx={{
          width: "20px",
          height: "20px",
          mb: 2,
          p: 1,
          borderRadius: "50%",
          bgcolor: primaryColors.gray[100],
          color: primaryColors.gray[600],
        }}
      />
    );
    helperContentForPhoneNumbersExcelSheetSection = (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="text-md" color={primaryColors.gray[500]}>
          <span style={{ color: primaryColors.brand[500] }}>
            Click to upload
          </span>{" "}
          or drag and drop
        </Typography>
        <Typography cariant="text-xs" color={primaryColors.gray[500]}>
          5 MB max file size. Supported extensions .csv .xlsx
        </Typography>
      </Box>
    );
  }
  // End: Dynamic Content for Phone Numbers Extracting Excel Sheet Import section

  useEffect(
    function closeNotificationErrorWhenTemplateIsSelected() {
      if (
        selectedTemplateData !== undefined &&
        notificationState.isOpen &&
        notificationState.type === NOTIFICATIONS_TYPE.error &&
        notificationState.stickyTillClosed
      ) {
        closeNotification();
      }
    },
    [
      closeNotification,
      notificationState.isOpen,
      notificationState.stickyTillClosed,
      notificationState.type,
      selectedTemplateData,
    ]
  );

  return (
    <GenericAccordion
      title={
        <Typography
          variant="text-md"
          color={primaryColors.base.black}
          fontWeight={fontWeightVariations["semiBold-600"]}
        >
          Bulk Links
        </Typography>
      }
      preventExpansion={disabled}
      onClickCallback={function showNotificationErrorForMissinTemplateForBulkSMS() {
        if (disabled) {
          fireNotification({
            title: "Please, Select first a Branch, Division, and Template",
            type: NOTIFICATIONS_TYPE.error,
            verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
            horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
            width: NOTIFICATION_WIDTH.small,
            target: NOTIFICATIONS_TARGET.admin,
            stickyTillClosed: true,
          });
        }
      }}
      backgroundColor={primaryColors.gray[200]}
      body={
        <Box display="flex" flexDirection="column" gap={1}>
          {/* Start: Survey Type */}
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography
              variant="text-sm"
              fontWeight={fontWeightVariations["medium-500"]}
            >
              Survey Type
            </Typography>
            <FormControl
              fullWidth
              sx={{ bgcolor: primaryColors.base.white, height: "44px" }}
            >
              <Select
                value={stateOfBulkLinks.surveyType}
                onChange={function updateSurveryType(event) {
                  updateSelectedSurveyTypeHandler(event.target.value);
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{ height: "44px" }}
                disabled={disabled}
              >
                <MenuItem value={SURVEY_TYPES.Both}>Both</MenuItem>
                <MenuItem value={SURVEY_TYPES.Review}>Review</MenuItem>
                <MenuItem value={SURVEY_TYPES.Complaint}>Complaint</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {/* End: Survey Type */}
          {/* Start: CSV File Dropzone */}
          <Box {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <Box display="flex" flexDirection="column" alignItems="center">
              {iconForPhoneNumbersExcelSheetSection}
              {helperContentForPhoneNumbersExcelSheetSection}
            </Box>
          </Box>
          <Box
            display="flex"
            p={2}
            gap={2}
            bgcolor={primaryColors.warning[25]}
            border={1}
            borderColor={primaryColors.warning[300]}
            borderRadius={3}
            sx={{ cursor: "default" }}
          >
            <Warning
              sx={{
                width: "20px",
                height: "20px",
                color: primaryColors.warning[500],
              }}
            />
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography variant="text-md" color={primaryColors.warning[700]}>
                The column that contains the numbers must have a header (title){" "}
                <span
                  style={{ fontWeight: fontWeightVariations["semiBold-600"] }}
                >
                  Phone Number
                </span>{" "}
                or{" "}
                <span
                  style={{ fontWeight: fontWeightVariations["semiBold-600"] }}
                >
                  Mobile Number
                </span>
              </Typography>
              <Typography variant="text-sm" color={primaryColors.warning[600]}>
                All Mobile Numbers should start with +2, 20 OR 0
              </Typography>
            </Box>
          </Box>
          {/* End: CSV File Dropzone */}
          {/* Start: Submit Button */}
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography
              variant="text-sm"
              fontWeight={fontWeightVariations["medium-500"]}
            >
              Email
            </Typography>
            <Box display="flex" gap={2}>
              <FormControl
                fullWidth
                sx={{ bgcolor: primaryColors.base.white, height: "44px" }}
                disabled={disabled}
              >
                <OutlinedInput
                  placeholder="Please enter a valid Email to Receive the Links"
                  value={stateOfBulkLinks.email}
                  onChange={function (event) {
                    updateEmailHandler(event.target.value);
                  }}
                  sx={{ height: "44px" }}
                />
              </FormControl>
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  color: primaryColors.base.white,
                  height: 42,
                  boxShadow: "none",
                }}
                disabled={!readyToSendBulkLinks || disabled}
                onClick={submitValidNumbersForLinkGeneration}
              >
                <Typography
                  variant="text-md"
                  fontWeight={fontWeightVariations["medium-500"]}
                >
                  Send
                </Typography>
              </Button>
            </Box>
          </Box>
          {/* End: Submit Button */}
        </Box>
      }
    />
  );
};

const bulkLinksDefaultState = {
  surveyType: SURVEY_TYPES.Both,
  importedValidPhoneNumbers: undefined,
  importedInvalidPhoneNumbers: [],
  email: undefined,
  emailIsValid: false,
};

const bulkLinksReducer = (state, action) => {
  switch (action.type) {
    case bulkLinksDispatchActions["UPDATE-SELECTED-SURVEY-TYPE"]: {
      return {
        ...state,
        surveyType: action.payload.surveyType,
      };
    }
    case bulkLinksDispatchActions["UPDATE-IMPORTED-PHONE-NUMBERS"]: {
      return {
        ...state,
        importedValidPhoneNumbers: action.payload.importedValidPhoneNumbers,
        importedInvalidPhoneNumbers: action.payload.importedInvalidPhoneNumbers,
      };
    }
    case bulkLinksDispatchActions["UPDATE-EMAIL"]: {
      const emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
      return {
        ...state,
        email: action.payload.email,
        emailIsValid: emailRegex.test(action.payload.email),
      };
    }
    default: {
      return state;
    }
  }
};

export const bulkLinksDispatchActions = {
  "UPDATE-SELECTED-SURVEY-TYPE": "UPDATE-SELECTED-SURVEY-TYPE",
  "UPDATE-IMPORTED-PHONE-NUMBERS": "UPDATE-IMPORTED-PHONE-NUMBERS",
  "UPDATE-EMAIL": "UPDATE-EMAIL",
};

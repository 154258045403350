import { Box, Skeleton, Typography } from "@mui/material";
import { RevampedLandingPageCard } from "../../../revamped-landing-page/components/RevampedLandingPage";
import { fontWeightVariations } from "../../../../helpers/customFont";
import { primaryColors } from "../../../../helpers/customColors";
import { RevampedVerticalBarChart } from "../../../../reusable/components/charts";
import { DynamicFilters } from "../shared/DynamicFilters";
import { useDynamicFiltersState } from "../../hooks/useDynamicFiltersState";
import { useCallback, useContext } from "react";
import { useGetRatingsComparison } from "../../api/reputation-dashboard";
import { AuthenticationContext } from "../../../login/contexts/AuthenticationContext";
import { PROFILE_CATEGORY_IDS } from "../../../../helpers/constants";

export const RatingsComparisonBarChart = ({
  startDate,
  endDate,
  governorates,
  facilityTypes,
  branches,
}) => {
  const { user } = useContext(AuthenticationContext);
  const profileCategoryID = user.profile["category"]?.id;

  const getFiltersData = useCallback(function prepareFiltersData() {
    return [
      {
        dropdownName: "insightType",
        dropdownOptions: insightsOptions,
        disableDropdown:
          profileCategoryID !== PROFILE_CATEGORY_IDS.FoodAndBeverage,
      },
    ];
  }, []);
  const { selectedFiltersState, updateSelectedFilters } =
    useDynamicFiltersState({
      isEachFilterIndependent: true,
      serverFiltersData: getFiltersData(),
      initialFiltersState: {
        insightType: 0,
      },
    });
  const {
    data: ratingsComparisonServerResponse,
    isFetching: isFetchingRatingsComparison,
  } = useGetRatingsComparison({
    params: {
      startDate,
      endDate,
      governorates,
      facilityTypes,
      branches,
      chartType: insightsOptions[selectedFiltersState?.insightType]?.type,
    },
    config: { staleTime: 120000 },
  });

  return (
    <RevampedLandingPageCard
      showSeparator={true}
      labelHeight="30px"
      label={
        <Typography
          variant="text-lg"
          fontWeight={fontWeightVariations.medium}
          color={primaryColors.gray[900]}
        >
          Ratings Comparison
        </Typography>
      }
    >
      <Box display="flex" flexDirection="column" height="400px" gap={2}>
        <DynamicFilters
          selectedFiltersState={selectedFiltersState}
          updateSelectedFilters={updateSelectedFilters}
        />
        {isFetchingRatingsComparison ? (
          <SkeletonForRatingsBarChart />
        ) : (
          <RevampedVerticalBarChart
            rotateXAxisTick={true}
            data={ratingsComparisonServerResponse?.data?.map(
              function sliceLongNames(branchData) {
                const isTextLong = branchData.name.length > 15;
                const getTrimmedName = (name) => {
                  return name.length > 12 ? `${name.slice(0, 12)}..` : name;
                };
                return {
                  ...branchData,
                  name: isTextLong
                    ? getTrimmedName(branchData.name)
                    : branchData.name,
                  fullName: branchData.name,
                };
              }
            )}
            valueType={"plain_number"}
            YAxisScale={[0, 1, 2, 3, 4, 5]}
            getBarColor={getRatingBarColor}
          />
        )}
      </Box>
    </RevampedLandingPageCard>
  );
};

const SkeletonForRatingsBarChart = () => {
  return (
    <Skeleton
      variant="rectangular"
      width={"100%"}
      height={400}
      sx={{ borderRadius: 5 }}
    />
  );
};
export function getRatingBarColor(value) {
  if (value <= 1) return "#ff0000";
  else if (value <= 2) return "#ff6666";
  else if (value <= 3) return "#ffcc99";
  else if (value <= 4) return "#99cc99";
  else if (value <= 5) return "#008000";
}
const insightsOptions = [
  {
    value: 0,
    label: "Google Reviews",
    type: "google-reviews",
  },
  {
    value: 1,
    label: "Tripadvisor Reviews",
    type: "tripadvisor-reviews",
  },
];

import { IconButton, Tooltip } from "@mui/material";
import { OverallViewTable } from "../../../revamped-overall-view/components/shared/OverallViewTable";
import { CloudDownload } from "@mui/icons-material";
import { useMemo } from "react";
import moment from "moment";
import { TableContainer } from "../../../../reusable/components/TableContainer";

export const InvoicesTable = () => {
  const complaintsTableColumns = useMemo(
    () => [
      {
        header: "Date",
        id: "date",
        accessorFn: function renderInvoiceDate(invoiceData) {
          return moment(invoiceData.date).format("YYYY-MM-DD");
        },
        size: 50,
      },
      {
        header: "Plan",
        accessorKey: "plan",
        size: 50,
      },
      {
        header: "Total active users",
        accessorKey: "totalActiveUsers",
        size: 50,
      },
      {
        header: "Total Branches",
        accessorKey: "totalBranches",
        size: 50,
      },
      {
        header: "Total Divisions",
        accessorKey: "totalDivisions",
        size: 50,
      },
      {
        header: "Invoice Total",
        accessorKey: "invoiceTotal",
        size: 50,
      },
      {
        header: "Status",
        id: "status",
        accessorFn: function renderInvoiceStatus(invoiceData) {
          return invoiceData.status;
        },
        size: 50,
      },
      {
        header: "",
        id: "download-invoice-button",
        accessorFn: function renderInvoiceDownloadButton(invoiceData) {
          return (
            <Tooltip title="Download Invoice">
              <IconButton size="small" onClick={function onClickDownloadInvoiceHandler(e){
                e.stopPropagation();
              }}>
                <CloudDownload />
              </IconButton>
            </Tooltip>
          );
        },
        size: 50,
      },
    ],
    []
  );

  return (
    <TableContainer label={"Invoices"}>
      <OverallViewTable
        columns={complaintsTableColumns}
        data={tempInvoicesData}
        totalRecords={0}
        isLoading={false}
        isError={false}
        externalPaginationProps={{
          externalCurrentPage: 1,
          updateExternalCurrentPage: () => undefined,
          isFetching: false,
        }}
        uniqueColumnName="id"
        // renderRowDetails={({ row }) => {
        //   return <>Row Detailssssssssssss</>;
        // }}
      />
    </TableContainer>
  );
};

const tempInvoicesData = [
  {
    date: 1687812693,
    plan: "Standard",
    totalActiveUsers: 4,
    totalBranches: 2,
    totalDivisions: 4,
    invoiceTotal: 23,
    status: "Failed",
  },
  {
    date: 1687812693,
    plan: "Premium",
    totalActiveUsers: 1,
    totalBranches: 4,
    totalDivisions: 2,
    invoiceTotal: 1,
    status: "Succeeded",
  },
  {
    date: 1687812693,
    plan: "Standard",
    totalActiveUsers: 4,
    totalBranches: 2,
    totalDivisions: 4,
    invoiceTotal: 23,
    status: "Failed",
  },
  {
    date: 1687812693,
    plan: "Standard",
    totalActiveUsers: 4,
    totalBranches: 2,
    totalDivisions: 4,
    invoiceTotal: 23,
    status: "Failed",
  },
  {
    date: 1687812693,
    plan: "Standard",
    totalActiveUsers: 4,
    totalBranches: 2,
    totalDivisions: 4,
    invoiceTotal: 23,
    status: "Failed",
  },
];

import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";
import {
  NOTIFICATIONS_HORIZONTAL_POSITION,
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATION_WIDTH,
  NotificationContext,
} from "../../../contexts/NotificationContext";
import { API_URL, melior } from "../../../helpers/constants";

const sendBulkdQRCodesForUnits = ({
  branchID,
  divisionID,
  templateID,
  validUnitsData,
  type,
  email,
}) => {
  const requestURL = `${API_URL}/feedback-invitation/qr-code-rooms
  `;

  return melior.post(requestURL, {
    branch_id: branchID,
    division_id: divisionID,
    template_id: templateID,
    room_numbers: validUnitsData,
    type,
    email,
  });
};

export const useSendBulkQRCodesForUnits = ({ config, params } = {}) => {
  const { fireNotification } = useContext(NotificationContext);

  return useMutation({
    onSuccess: (data, variables) => {
      fireNotification({
        title: `QR Codes will be generated for the submitted Units`,
        type: NOTIFICATIONS_TYPE.success,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
        horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
        width: NOTIFICATION_WIDTH.small,
        target: NOTIFICATIONS_TARGET.admin,
      });
    },
    onError: function(error){
      fireNotification({
        title:
          "Failed to Submit the request to send Links, please try again later.",
        description: error.response.data.detail,
        type: NOTIFICATIONS_TYPE.error,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
        horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
        width: NOTIFICATION_WIDTH.small,
        target: NOTIFICATIONS_TARGET.admin,
      });
    },
    ...config,
    mutationFn: sendBulkdQRCodesForUnits,
  });
};

import {
  Box,
  Button,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { GenericAccordion } from "../../../reusable/components/GenericAccordion";
import { primaryColors } from "../../../helpers/customColors";
import { fontWeightVariations } from "../../../helpers/customFont";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import {
  NOTIFICATIONS_HORIZONTAL_POSITION,
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATION_WIDTH,
  NotificationContext,
} from "../../../contexts/NotificationContext";
import readXlsxFile from "read-excel-file";
import {
  acceptStyle,
  baseStyle,
  focusedStyle,
  rejectStyle,
} from "./sharedVariables";
import { CloudUpload, FileDownloadDone, Warning } from "@mui/icons-material";
import { SURVEY_TYPES } from "./sharedVariables";
import { DatePicker, LoadingButton } from "@mui/lab";
import moment from "moment";
import { InvalidPhoneNumbersDialog } from "./InvalidPhoneNumbersDialog";
import { useSendBulkSMSes } from "../api/sendBulkSMSes";

export const GenerateBulkSMSAccordion = ({
  disabled,
  selectedBranchData,
  selectedDivisionData,
  selectedTemplateData,
}) => {
  const { fireNotification, closeNotification, notificationState } =
    useContext(NotificationContext);
  const sendBulkSMSesMutation = useSendBulkSMSes();

  // Start: Reducer State Management
  const [stateOfBulkSMS, dispatchAction] = useReducer(
    bulkSMSGenerationReducer,
    bulkSMSGenerationDefaultState
  );
  const previousScheduledDateRef = useRef(stateOfBulkSMS.scheduledDate);

  function updateSelectedSurveyTypeHandler(updatedSurveyType) {
    dispatchAction({
      type: bulkSMSGenerationDispatchActions["UPDATE-SELECTED-SURVEY-TYPE"],
      payload: { surveyType: updatedSurveyType },
    });
  }

  function updateImportedPhoneNumbersHandler({
    extractedValidPhoneNumbers,
    extractedInvalidPhoneNumbers,
  }) {
    dispatchAction({
      type: bulkSMSGenerationDispatchActions["UPDATE-IMPORTED-PHONE-NUMBERS"],
      payload: {
        importedValidPhoneNumbers: extractedValidPhoneNumbers,
        importedInvalidPhoneNumbers: extractedInvalidPhoneNumbers,
      },
    });
  }

  const updateScheduledTimeHandler = (newTime) => {
    dispatchAction({
      type: bulkSMSGenerationDispatchActions["UPDATE-SCHEDULED-TIME"],
      payload: { scheduledTime: newTime },
    });
  };

  const updateScheduledDateHandler = (newDate) => {
    dispatchAction({
      type: bulkSMSGenerationDispatchActions["UPDATE-SCHEDULED-DATE"],
      payload: { scheduledDate: moment(newDate) },
    });

    previousScheduledDateRef.current = stateOfBulkSMS.scheduledDate;
  };

  const updateAvailableTimingsHandler = (newAvailableTimings) => {
    dispatchAction({
      type: bulkSMSGenerationDispatchActions["UPDATE-AVAULABLE-TIMINGS"],
      payload: { availableTimings: newAvailableTimings },
    });
  };
  // End: Reducer State Management

  // Start: React Dropzone
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".csv",
          ".xlsx",
        ],
        "application/vnd.ms-excel": [".csv", ".xlsx"],
      },
      onDropRejected: function () {
        fireNotification({
          title: "Error in Uploaded Phone Numbers Sheet",
          description: "Invalid File Type (Must be .csv or .xlsx)",
          type: NOTIFICATIONS_TYPE.error,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
          horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
          target: NOTIFICATIONS_TARGET.admin,
          width: NOTIFICATION_WIDTH.small,
        });
      },
      onDropAccepted: function (uploadedFiles) {
        try {
          readXlsxFile(uploadedFiles[0])
            .then((currSheet) => {
              let columnNames = currSheet[0];
              let index = columnNames.indexOf(
                columnNames.find(
                  (rowName) =>
                    rowName.toLowerCase().includes("phone") ||
                    rowName.toLowerCase().includes("mobile")
                )
              );

              if (index === -1) {
                console.error("Column for phone numbers not found");

                fireNotification({
                  title: "Error in Uploaded Phone Numbers Sheet",
                  description:
                    "Couldn't find a column labeled (Phone Number or Mobile Number)",
                  type: NOTIFICATIONS_TYPE.error,
                  verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
                  horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
                  target: NOTIFICATIONS_TARGET.admin,
                  width: NOTIFICATION_WIDTH.small,
                });
              } else {
                let extractedInvalidPhoneNumbers = [];
                let phoneNumbersMissingZero = [];
                // let totalPhoneNumberRecords = 0;

                const extractedValidPhoneNumbers = currSheet.reduce(
                  (accumlator, currRow) => {
                    const currentNumber = currRow[index]?.toString();
                    if (
                      !!currentNumber &&
                      currentNumber?.trim().length &&
                      currentNumber !== columnNames[index]
                    ) {
                      //   totalPhoneNumberRecords += 1;
                      if (
                        (currentNumber.length === 11 &&
                          currentNumber[0] === "0") ||
                        (currentNumber.length === 12 &&
                          currentNumber.substring(0, 2) === "20") ||
                        (currentNumber.length === 13 &&
                          currentNumber.substring(0, 3) === "+20")
                      ) {
                        accumlator.push(currentNumber);
                      } else {
                        if (
                          currentNumber.length === 10 &&
                          currentNumber[0] === "1"
                        ) {
                          phoneNumbersMissingZero.push(currentNumber);
                        } else {
                          extractedInvalidPhoneNumbers.push(currentNumber);
                        }
                      }
                    }
                    return accumlator;
                  },
                  []
                );

                // Don't update the Phone Numbers and notify the user in case none was found.
                if (
                  extractedValidPhoneNumbers.length === 0 &&
                  extractedInvalidPhoneNumbers.length === 0
                ) {
                  fireNotification({
                    title: "Error in Uploaded Phone Numbers Sheet",
                    description:
                      "No Phone Numbers found in the imported Excel Sheet (Add at least 1)",
                    type: NOTIFICATIONS_TYPE.error,
                    verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
                    horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
                    target: NOTIFICATIONS_TARGET.admin,
                    width: NOTIFICATION_WIDTH.small,
                  });
                }

                // Update the Phone Numbers state in case any of the valid or invalid numbers found.
                if (
                  extractedValidPhoneNumbers.length > 0 ||
                  extractedInvalidPhoneNumbers.length > 0
                ) {
                  updateImportedPhoneNumbersHandler({
                    extractedValidPhoneNumbers: extractedValidPhoneNumbers,
                    extractedInvalidPhoneNumbers: extractedInvalidPhoneNumbers,
                  });
                }
              }
            })
            .catch((error) =>
              console.error("Failed to extract Phone Numbers", error)
            );
        } catch (exception) {
          console.error(
            "Error in try block for extracting Phone Numbers",
            exception
          );
        }
      },
      disabled,
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  // End: React Dropzone

  const readyToSendBulkSMSes =
    stateOfBulkSMS.surveyType !== undefined &&
    stateOfBulkSMS.importedValidPhoneNumbers !== undefined &&
    stateOfBulkSMS.importedValidPhoneNumbers.length > 0;

  // Start: Dynamic Content for Phone Numbers Extracting Excel Sheet Import section
  let iconForPhoneNumbersExcelSheetSection = undefined;
  let helperContentForPhoneNumbersExcelSheetSection = undefined;
  if (stateOfBulkSMS.importedValidPhoneNumbers?.length >= 0) {
    // It means there's a file that was imported successfully
    iconForPhoneNumbersExcelSheetSection = (
      <FileDownloadDone
        sx={{
          width: "20px",
          height: "20px",
          mb: 2,
          p: 1,
          borderRadius: "50%",
          bgcolor: primaryColors.success[50],
          color: primaryColors.success[600],
        }}
      />
    );
    helperContentForPhoneNumbersExcelSheetSection = (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="text-md" color={primaryColors.success[600]}>
          Phone Numbers Excel Sheet Imported Successfully
        </Typography>
        <Typography cariant="text-xs" color={primaryColors.gray[500]}>
          Valid Phone Numbers Count:{" "}
          <span>{stateOfBulkSMS.importedValidPhoneNumbers?.length}</span>
        </Typography>
        <Box display="flex" alignItems="center">
          <InvalidPhoneNumbersDialog
            invalidPhoneNumbers={stateOfBulkSMS.importedInvalidPhoneNumbers}
            triggerButtonLabel={`Invalid Phone Numbers Count: ${stateOfBulkSMS.importedInvalidPhoneNumbers?.length}`}
            submitValidNumbers={submitValidNumbersForSMSGeneration}
            isSubmittingValidNumbers={sendBulkSMSesMutation.isLoading}
          />
        </Box>
      </Box>
    );
  } else {
    iconForPhoneNumbersExcelSheetSection = (
      <CloudUpload
        sx={{
          width: "20px",
          height: "20px",
          mb: 2,
          p: 1,
          borderRadius: "50%",
          bgcolor: primaryColors.gray[100],
          color: primaryColors.gray[600],
        }}
      />
    );
    helperContentForPhoneNumbersExcelSheetSection = (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="text-md" color={primaryColors.gray[500]}>
          <span style={{ color: primaryColors.brand[500] }}>
            Click to upload
          </span>{" "}
          or drag and drop
        </Typography>
        <Typography cariant="text-xs" color={primaryColors.gray[500]}>
          5 MB max file size. Supported extensions .csv .xlsx
        </Typography>
      </Box>
    );
  }
  // End: Dynamic Content for Phone Numbers Extracting Excel Sheet Import section

  function submitValidNumbersForSMSGeneration() {
    const [hour, minute] =
      stateOfBulkSMS.scheduledTime !== 0
        ? stateOfBulkSMS.scheduledTime.split(":")
        : [moment().hour(), moment().minute()];

    const dateAndTimeObject = stateOfBulkSMS.scheduledDate.set({
      hour: Number(hour),
      minute: Number(minute),
    });

    const dateAndTimeISOString = dateAndTimeObject.toISOString();

    sendBulkSMSesMutation.mutateAsync({
      branchID: selectedBranchData.id,
      divisionID: selectedDivisionData.id,
      templateID: selectedTemplateData.id,
      validPhoneNumbers: stateOfBulkSMS.importedValidPhoneNumbers,
      scheduledDate: dateAndTimeISOString,
      type: stateOfBulkSMS.surveyType,
    });
  }

  useEffect(
    function updateTimeAutomaticallyUponDateChange() {
      const currentHour = moment().hour();
      const currentMinute = moment().minute();
      const currentDate = moment();

      const selectedDateAfterToday =
        stateOfBulkSMS.scheduledDate.isAfter(currentDate);

      const availableTimingsTemp = selectedDateAfterToday
        ? hours
        : hours.filter((hourData, index) =>
            timeIsValid({
              currentHour,
              currentMinute,
              timeToCheck: hourData.value,
            })
          );

      // Add Now time if the date is today
      if (!selectedDateAfterToday) {
        availableTimingsTemp.unshift({ label: "Now", value: 0, bold: true });
      }

      const selectedScheduledDateIsChanged =
        previousScheduledDateRef.current.toISOString() !==
        stateOfBulkSMS.scheduledDate.toISOString();

      // Only update the selected time when selected scheduled date is changed
      if (selectedScheduledDateIsChanged) {
        updateScheduledTimeHandler(availableTimingsTemp[0].value);
      }

      updateAvailableTimingsHandler(availableTimingsTemp);
    },
    [stateOfBulkSMS.scheduledDate]
  );

  useEffect(
    function closeNotificationErrorWhenTemplateIsSelected() {
      if (
        selectedTemplateData !== undefined &&
        notificationState.isOpen &&
        notificationState.type === NOTIFICATIONS_TYPE.error &&
        notificationState.stickyTillClosed
      ) {
        closeNotification();
      }
    },
    [
      closeNotification,
      notificationState.isOpen,
      notificationState.stickyTillClosed,
      notificationState.type,
      selectedTemplateData,
    ]
  );

  const timeSelectMenu = useMemo(() => {
    return (
      <FormControl
        fullWidth
        sx={{ bgcolor: primaryColors.base.white, height: "44px" }}
      >
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={stateOfBulkSMS.scheduledTime}
          defaultValue={stateOfBulkSMS.scheduledTime}
          onChange={function (event) {
            updateScheduledTimeHandler(event.target.value);
          }}
          MenuProps={{ style: { maxHeight: 500 } }}
          sx={{ height: "44px", width: "100%" }}
        >
          {stateOfBulkSMS.availableTimings.map((timeData, index) => (
            <MenuItem
              value={timeData.value}
              sx={{
                fontWeight: timeData.bold ? "bold" : "",
                fontSize: timeData.bold ? "1rem" : "",
              }}
            >
              {timeData.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }, [stateOfBulkSMS.availableTimings, stateOfBulkSMS.scheduledTime]);

  return (
    <GenericAccordion
      title={
        <Typography
          variant="text-md"
          color={primaryColors.base.black}
          fontWeight={fontWeightVariations["semiBold-600"]}
        >
          Bulk SMS
        </Typography>
      }
      preventExpansion={disabled}
      onClickCallback={function showNotificationErrorForMissinTemplateForBulkSMS() {
        if (disabled) {
          fireNotification({
            title: "Please, Select first a Branch, Division, and Template",
            type: NOTIFICATIONS_TYPE.error,
            verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
            horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
            width: NOTIFICATION_WIDTH.small,
            target: NOTIFICATIONS_TARGET.admin,
            stickyTillClosed: true,
          });
        }
      }}
      backgroundColor={primaryColors.gray[200]}
      body={
        <Box display="flex" flexDirection="column" gap={1}>
          {/* Start: Bulk SMS Date, Time and Survey Type */}
          <Box display="flex" gap={2}>
            {/* Start: Date Selection */}
            <Box display="flex" flexDirection="column" gap={0.5} width="33">
              <Typography
                variant="text-sm"
                fontWeight={fontWeightVariations["medium-500"]}
              >
                Date
              </Typography>
              <DatePicker
                value={stateOfBulkSMS.scheduledDate}
                minDate={new Date()}
                InputProps={{
                  sx: {
                    height: "44px",
                    bgcolor: primaryColors.base.white,
                  },
                }}
                onChange={(newValue) => {
                  updateScheduledDateHandler(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} sx={{ height: "44px" }} />
                )}
              />
            </Box>
            {/* End: Date Selection */}
            {/* Start: Time Selection */}
            <Box display="flex" flexDirection="column" gap={0.5} width="33%">
              <Typography
                variant="text-sm"
                fontWeight={fontWeightVariations["medium-500"]}
              >
                Time
              </Typography>
              {timeSelectMenu}
            </Box>
            {/* End: Time Selection */}
            {/* Start: Survey Type */}
            <Box display="flex" flexDirection="column" gap={0.5} width="33%">
              <Typography
                variant="text-sm"
                fontWeight={fontWeightVariations["medium-500"]}
              >
                Survey Type
              </Typography>
              <FormControl
                fullWidth
                sx={{ bgcolor: primaryColors.base.white, height: "44px" }}
              >
                <Select
                  value={stateOfBulkSMS.surveyType}
                  onChange={function updateSurveryType(event) {
                    updateSelectedSurveyTypeHandler(event.target.value);
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ height: "44px" }}
                  disabled={disabled}
                >
                  <MenuItem value={SURVEY_TYPES.Both}>Both</MenuItem>
                  <MenuItem value={SURVEY_TYPES.Review}>Review</MenuItem>
                  <MenuItem value={SURVEY_TYPES.Complaint}>Complaint</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {/* End: Survey Type */}
          </Box>
          {/* End: Bulk SMS Date, Time and Survey Type */}
          {/* Start: CSV File Dropzone */}
          <Box {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <Box display="flex" flexDirection="column" alignItems="center">
              {iconForPhoneNumbersExcelSheetSection}
              {helperContentForPhoneNumbersExcelSheetSection}
            </Box>
          </Box>
          {invalidNumbersHelperSection}
          {/* End: CSV File Dropzone */}

          {/* Start: Submit Button */}
          <Box display="flex" flexDirection="column" gap={0.5}>
            <LoadingButton
              loading={sendBulkSMSesMutation.isLoading}
              variant="contained"
              sx={{
                textTransform: "capitalize",
                color: primaryColors.base.white,
                height: 42,
                boxShadow: "none",
              }}
              disabled={!readyToSendBulkSMSes || disabled}
              onClick={submitValidNumbersForSMSGeneration}
            >
              <Typography
                variant="text-md"
                fontWeight={fontWeightVariations["medium-500"]}
              >
                Send
              </Typography>
            </LoadingButton>
          </Box>
          {/* End: Submit Button */}
        </Box>
      }
    />
  );
};

const bulkSMSGenerationReducer = (state, action) => {
  switch (action.type) {
    case bulkSMSGenerationDispatchActions["UPDATE-SCHEDULED-DATE"]: {
      return {
        ...state,
        scheduledDate: action.payload.scheduledDate,
      };
    }
    case bulkSMSGenerationDispatchActions["UPDATE-SCHEDULED-TIME"]: {
      return {
        ...state,
        scheduledTime: action.payload.scheduledTime,
      };
    }
    case bulkSMSGenerationDispatchActions["UPDATE-AVAULABLE-TIMINGS"]: {
      return {
        ...state,
        availableTimings: action.payload.availableTimings,
      };
    }
    case bulkSMSGenerationDispatchActions["UPDATE-SELECTED-SURVEY-TYPE"]: {
      return {
        ...state,
        surveyType: action.payload.surveyType,
      };
    }
    case bulkSMSGenerationDispatchActions["UPDATE-IMPORTED-PHONE-NUMBERS"]: {
      return {
        ...state,
        importedValidPhoneNumbers: action.payload.importedValidPhoneNumbers,
        importedInvalidPhoneNumbers: action.payload.importedInvalidPhoneNumbers,
      };
    }

    default: {
      return state;
    }
  }
};

export const bulkSMSGenerationDispatchActions = {
  "UPDATE-SELECTED-SURVEY-TYPE": "UPDATE-SELECTED-SURVEY-TYPE",
  "UPDATE-SCHEDULED-DATE": "UPDATE-SCHEDULED-DATE",
  "UPDATE-SCHEDULED-TIME": "UPDATE-SCHEDULED-TIME",
  "UPDATE-IMPORTED-PHONE-NUMBERS": "UPDATE-IMPORTED-PHONE-NUMBERS",
  "UPDATE-AVAULABLE-TIMINGS": "UPDATE-AVAULABLE-TIMINGS",
  "UPDATE-EMAIL": "UPDATE-EMAIL",
};

const timeIsValid = ({ timeToCheck, currentHour, currentMinute }) => {
  const [hourToCheck, minuteToCheck] = timeToCheck.split(":");

  if (Number(hourToCheck) === Number(currentHour)) {
    if (Number(minuteToCheck) > Number(currentMinute)) {
      return true;
    }
  } else if (Number(hourToCheck) > Number(currentHour)) {
    return true;
  }

  return false;
};

const hours = [
  { value: "00:00", label: "00:00", bold: true },
  { value: "00:15", label: "00:15" },
  { value: "00:30", label: "00:30" },
  { value: "00:45", label: "00:45" },

  { value: "01:00", label: "01:00", bold: true },
  { value: "01:15", label: "01:15" },
  { value: "01:30", label: "01:30" },
  { value: "01:45", label: "01:45" },

  { value: "02:00", label: "02:00", bold: true },
  { value: "02:15", label: "02:15" },
  { value: "02:30", label: "02:30" },
  { value: "02:45", label: "02:45" },

  { value: "03:00", label: "03:00", bold: true },
  { value: "03:15", label: "03:15" },
  { value: "03:30", label: "03:30" },
  { value: "03:45", label: "03:45" },

  { value: "04:00", label: "04:00", bold: true },
  { value: "04:15", label: "04:15" },
  { value: "04:30", label: "04:30" },
  { value: "04:45", label: "04:45" },

  { value: "05:00", label: "05:00", bold: true },
  { value: "05:15", label: "05:15" },
  { value: "05:30", label: "05:30" },
  { value: "05:45", label: "05:45" },

  { value: "06:00", label: "06:00", bold: true },
  { value: "06:15", label: "06:15" },
  { value: "06:30", label: "06:30" },
  { value: "06:45", label: "06:45" },

  { value: "07:00", label: "07:00", bold: true },
  { value: "07:15", label: "07:15" },
  { value: "07:30", label: "07:30" },
  { value: "07:45", label: "07:45" },

  { value: "08:00", label: "08:00", bold: true },
  { value: "08:15", label: "08:15" },
  { value: "08:30", label: "08:30" },
  { value: "08:45", label: "08:45" },

  { value: "09:00", label: "09:00", bold: true },
  { value: "09:15", label: "09:15" },
  { value: "09:30", label: "09:30" },
  { value: "09:45", label: "09:45" },

  { value: "10:00", label: "10:00", bold: true },
  { value: "10:15", label: "10:15" },
  { value: "10:30", label: "10:30" },
  { value: "10:45", label: "10:45" },

  { value: "11:00", label: "11:00", bold: true },
  { value: "11:15", label: "11:15" },
  { value: "11:30", label: "11:30" },
  { value: "11:45", label: "11:45" },

  { value: "12:00", label: "12:00", bold: true },
  { value: "12:15", label: "12:15" },
  { value: "12:30", label: "12:30" },
  { value: "12:45", label: "12:45" },

  { value: "13:00", label: "13:00", bold: true },
  { value: "13:15", label: "13:15" },
  { value: "13:30", label: "13:30" },
  { value: "13:45", label: "13:45" },

  { value: "14:00", label: "14:00", bold: true },
  { value: "14:15", label: "14:15" },
  { value: "14:30", label: "14:30" },
  { value: "14:45", label: "14:45" },

  { value: "15:00", label: "15:00", bold: true },
  { value: "15:15", label: "15:15" },
  { value: "15:30", label: "15:30" },
  { value: "15:45", label: "15:45" },

  { value: "16:00", label: "16:00", bold: true },
  { value: "16:15", label: "16:15" },
  { value: "16:30", label: "16:30" },
  { value: "16:45", label: "16:45" },

  { value: "17:00", label: "17:00", bold: true },
  { value: "17:15", label: "17:15" },
  { value: "17:30", label: "17:30" },
  { value: "17:45", label: "17:45" },

  { value: "18:00", label: "18:00", bold: true },
  { value: "18:15", label: "18:15" },
  { value: "18:30", label: "18:30" },
  { value: "18:45", label: "18:45" },

  { value: "19:00", label: "19:00", bold: true },
  { value: "19:15", label: "19:15" },
  { value: "19:30", label: "19:30" },
  { value: "19:45", label: "19:45" },

  { value: "20:00", label: "20:00", bold: true },
  { value: "20:15", label: "20:15" },
  { value: "20:30", label: "20:30" },
  { value: "20:45", label: "20:45" },

  { value: "21:00", label: "21:00", bold: true },
  { value: "21:15", label: "21:15" },
  { value: "21:30", label: "21:30" },
  { value: "21:45", label: "21:45" },

  { value: "22:00", label: "22:00", bold: true },
  { value: "22:15", label: "22:15" },
  { value: "22:30", label: "22:30" },
  { value: "22:45", label: "22:45" },

  { value: "23:00", label: "23:00", bold: true },
  { value: "23:15", label: "23:15" },
  { value: "23:30", label: "23:30" },
  { value: "23:45", label: "23:45" },
];

const bulkSMSGenerationDefaultState = {
  surveyType: SURVEY_TYPES.Both,
  importedValidPhoneNumbers: undefined,
  importedInvalidPhoneNumbers: [],
  availableTimings: hours,
  scheduledDate: moment(),
  scheduledTime: 0,
};

export const invalidNumbersHelperSection = (
  <Box
    display="flex"
    p={2}
    gap={2}
    bgcolor={primaryColors.warning[25]}
    border={1}
    borderColor={primaryColors.warning[300]}
    borderRadius={3}
    sx={{ cursor: "default" }}
  >
    <Warning
      sx={{
        width: "20px",
        height: "20px",
        color: primaryColors.warning[500],
      }}
    />
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography variant="text-md" color={primaryColors.warning[700]}>
        The column that contains the numbers must have a header (title){" "}
        <span style={{ fontWeight: fontWeightVariations["semiBold-600"] }}>
          Phone Number
        </span>{" "}
        or{" "}
        <span style={{ fontWeight: fontWeightVariations["semiBold-600"] }}>
          Mobile Number
        </span>
      </Typography>
      <Typography variant="text-sm" color={primaryColors.warning[600]}>
        All Mobile Numbers should start with +2, 20 OR 0
      </Typography>
    </Box>
  </Box>
);

import { Box, IconButton, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { primaryColors } from "../../../helpers/customColors";
import { fontWeightVariations } from "../../../helpers/customFont";
import { RevampedLandingPageCard } from "./RevampedLandingPage";
import { Link } from "react-router-dom";
import { MenuOpen } from "@mui/icons-material";
import { getAllBranchesInsights } from "../api/getAllBranchesInsights";
import { useDynamicFiltersState } from "../../tabbed-landing-page/hooks/useDynamicFiltersState";
import { DynamicFilters } from "../../tabbed-landing-page/components/shared/DynamicFilters";
import { ComparisonTable } from "../../tabbed-landing-page/components/shared/ComparisonTable";

export const AllBranchesComparisonTable = ({
  startDate,
  endDate,
  governorates,
  facilityTypes,
  branches,
  divisions,
}) => {
  const [tableComparisonAPIData, setTableComparisonAPIData] = useState();
  const [isFetching, setIsFetching] = useState();

  const getFiltersData = useCallback(
    function prepareFiltersData() {
      return [
        {
          dropdownName: "rank",
          dropdownOptions: performanceRankingOptions,
          disableDropdown: divisions?.length > 0,
        },
      ];
    },
    [divisions?.length]
  );

  const { selectedFiltersState, updateSelectedFilters, replaceFilterState } =
    useDynamicFiltersState({
      isEachFilterIndependent: true,
      serverFiltersData: getFiltersData(),
      initialFiltersState: {
        rank: performanceRankingOptions[0].value,
      },
    });

  const callInsightsAPIs = useCallback(function fetchTableComparisonData({
    startDate,
    endDate,
    governorates,
    facilityTypes,
    branches,
    divisions,
    selectedRankingOption,
  }) {
    setIsFetching(true);
    getAllBranchesInsights({
      startDate,
      endDate,
      governorates,
      facilityTypes,
      branches,
      divisions,
      selectedRankingOption,
    })
      .then(function logData(response) {
        setTableComparisonAPIData(response.data.branches_comparison_data);
        setIsFetching(false);
      })
      .catch(function logError(error) {
        console.error("error while fetching table comparison data", error);
        setIsFetching(false);
      });
  },
  []);

  useEffect(
    // to update disableDropdown condition
    function updateFiltersData() {
      replaceFilterState({ newFiltersData: getFiltersData() });
    },
    [getFiltersData, replaceFilterState]
  );

  useEffect(
    function updateSelectedOptionForCompareDropdown() {
      let compareSelectedValue;
      const multipleBranchesSelected =
        branches.length !== 1 && divisions.length === 0;
      compareSelectedValue = multipleBranchesSelected
        ? performanceRankingOptions[0].value
        : performanceRankingOptions[1].value;
      updateSelectedFilters({
        target: { name: "rank", value: compareSelectedValue },
      });
    },
    [branches, divisions]
  );
  useEffect(
    function callInsightsAPIsOnMount() {
      callInsightsAPIs({
        startDate,
        endDate,
        governorates,
        facilityTypes,
        branches,
        divisions,
        selectedRankingOption: selectedFiltersState?.rank,
      });
    },
    [branches, callInsightsAPIs, divisions, endDate, facilityTypes, governorates, selectedFiltersState?.rank, startDate]
  );

  return (
    <RevampedLandingPageCard
      showSeparator={true}
      labelHeight="30px"
      label={
        <Typography
          variant="text-lg"
          fontWeight={fontWeightVariations.medium}
          color={primaryColors.gray[900]}
        >
          Performance Ranking
        </Typography>
      }
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <DynamicFilters
            selectedFiltersState={selectedFiltersState}
            updateSelectedFilters={updateSelectedFilters}
          />
          <Link title="Go to Reviews Page" to={`/reviews`}>
            <IconButton size="large" sx={{ transform: "rotate(180deg)" }}>
              <MenuOpen />
            </IconButton>
          </Link>
        </Box>
        {
          <ComparisonTable
            isFetching={isFetching}
            tableComparisonAPIData={tableComparisonAPIData}
            selectedRankingOption={selectedFiltersState?.rank}
          />
        }
      </Box>
    </RevampedLandingPageCard>
  );
};

export const performanceRankingOptions = [
  {
    label: "Rank Branches",
    value: "rank_branches",
  },
  {
    label: "Rank Divisions",
    value: "rank_divisions",
  },
];

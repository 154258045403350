import { Box } from "@mui/material";
import { ComplaintsRecurrenceBarChart } from "./ComplaintsRecurrenceBarChart";
import { ComplaintsClassificationBarChart } from "./ComplaintsClassificationBarChart";
import { ComplaintsMetricsComparisonBarChart } from "./ComplaintsMetricsComparisonBarChart";
import { SpecificBranchesQuestionsInsights } from "../../../revamped-landing-page/components/SpecificBranchesQuestionsInsights";
import {
  useGetComplaintsClassificationDropdowns,
  useGetComplaintsOverviewCards,
} from "../../api/complaints-overview";
import { useContext, useEffect, useState } from "react";
import { ComplaintsInsightsCards } from "./ComplaintsInsightsCards";
import {
  markSelectedBranches,
  markSelectedDivisions,
} from "../../hooks/useInsightsFiltersState";
import { AuthenticationContext } from "../../../login/contexts/AuthenticationContext";
import { renameDropdownFields } from "../../hooks/useDynamicFiltersState";

export const ComplaintsOverview = ({
  selectedFiltersState,
  branchesAndDivisionsData,
}) => {
  const [
    complaintsClassificationDropdownsData,
    setComplaintsClassificationDropdownsData,
  ] = useState(undefined);
  const { canManageLabels } = useContext(AuthenticationContext);

  const {
    data: complaintsClassificationDropdownsServerResponse,
    isFetching: isFetchingClassificationDropdowns,
    refetch: refetchClassifiactionDropdowns,
  } = useGetComplaintsClassificationDropdowns({
    config: { enabled: false },
  });

  const { data: complaintsOverviewCards, isFetching: isFetchingOverviewCards } =
    useGetComplaintsOverviewCards({
      params: {
        startDate: selectedFiltersState.startDate,
        endDate: selectedFiltersState.endDate,
        governorates: selectedFiltersState.governorates,
        facilityTypes: selectedFiltersState.facilityTypes,
        branches: selectedFiltersState.branches,
        divisions: selectedFiltersState.divisions,
        relatedTeams: selectedFiltersState.relatedTeams,
      },
      config: { staleTime: 120000 },
    });

  useEffect(
    function fetchClassificationDropdownsServerResponse() {
      if (canManageLabels) refetchClassifiactionDropdowns();
    },
    [canManageLabels, refetchClassifiactionDropdowns]
  );
  useEffect(
    function fetchClassificationDropdownsServerResponse() {
      setComplaintsClassificationDropdownsData(
        renameDropdownFields(
          complaintsClassificationDropdownsServerResponse?.data
        )
      );
    },
    [complaintsClassificationDropdownsServerResponse?.data]
  );
  return (
    <Box display="flex" flexDirection="column" gap="20px">
      {/* First Section */}
      <ComplaintsInsightsCards
        rawNumbersCardsData={complaintsOverviewCards?.data?.main_cards}
        selectedStartDate={selectedFiltersState.startDate}
        selectedEndDate={selectedFiltersState.endDate}
        isFetching={isFetchingOverviewCards}
      />

      {/* Second Section */}
      <SpecificBranchesQuestionsInsights
        rawQuestionsInsightsData={complaintsOverviewCards?.data?.big_cards}
        branchesData={markSelectedBranches({
          branchesData: branchesAndDivisionsData?.branches,
          selectedBranchesIDs: selectedFiltersState.branches,
        })}
        divisionsData={markSelectedDivisions({
          divisionsData: branchesAndDivisionsData?.divisions,
          selectedDivisionsIDs: selectedFiltersState.divisions,
        })}
        isFetching={isFetchingOverviewCards}
        selectedStartDate={selectedFiltersState.startDate}
        selectedEndDate={selectedFiltersState.endDate}
        insightsInfo={insightsInfo}
      />

      {/* Third Section */}
      <ComplaintsMetricsComparisonBarChart
        startDate={selectedFiltersState.startDate}
        endDate={selectedFiltersState.endDate}
        governorates={selectedFiltersState.governorates}
        facilityTypes={selectedFiltersState.facilityTypes}
        branches={selectedFiltersState.branches}
        divisions={selectedFiltersState.divisions}
        relatedTeams={selectedFiltersState.relatedTeams}
      />

      {canManageLabels && (
        <>
          {/* Fourth Section */}
          <ComplaintsClassificationBarChart
            startDate={selectedFiltersState.startDate}
            endDate={selectedFiltersState.endDate}
            governorates={selectedFiltersState.governorates}
            facilityTypes={selectedFiltersState.facilityTypes}
            branches={selectedFiltersState.branches}
            divisions={selectedFiltersState.divisions}
            relatedTeams={selectedFiltersState.relatedTeams}
            complaintsClassificationDropdowns={
              complaintsClassificationDropdownsData
            }
          />

          {/* Fifth Section */}
          <ComplaintsRecurrenceBarChart
            startDate={selectedFiltersState.startDate}
            endDate={selectedFiltersState.endDate}
            governorates={selectedFiltersState.governorates}
            facilityTypes={selectedFiltersState.facilityTypes}
            branches={selectedFiltersState.branches}
            divisions={selectedFiltersState.divisions}
            relatedTeams={selectedFiltersState.relatedTeams}
            complaintsClassificationDropdowns={
              complaintsClassificationDropdownsData
            }
          />
        </>
      )}
    </Box>
  );
};

const insightsInfo = {
  CSAT: {
    title: "Resolution Satisfaction ",
    question: "How satisfied are you from the way your complaint was managed?",
    description:
      "The Resolution Satisfaction CSAT score is the count of all positive responses (4 or 5), divided by the total number of responses collected, then multiplied by 100. The outcome is basically the percentage of satisfied customers upon resolution of their complaints. Naturally, this is calculated from all received post-resolution satisfaction surveys.",
  },
  time: {
    title: "Average Resolution Time",
    description:
      "This is the average time it took complaints to be resolved, from the moment the complaint was communicated till it was marked as “Resolved”.",
  },
  rate: {
    title: "Resolution Rate",
    description:
      "This number indicates the percentage of resolved complaints from the total of received complaints within the set timeframe.",
  },
};

import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Tooltip } from "@mui/material";
import { primaryColors } from "../../../../../helpers/customColors";
import {
  GenericDialog,
  SUBMIT_BUTTON_TYPES,
} from "../../../../../reusable/components/GenericDialog";
import {
  NotificationContext,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATIONS_TARGET,
} from "../../../../../contexts/NotificationContext";
import { useContext } from "react";
import { useDeleteExistingMember } from "../../../api/members/deleteExistingMember";

export const DeleteExistingMember = ({ memberData }) => {
  const { fireNotification } = useContext(NotificationContext);
  const deleteMemberMutation = useDeleteExistingMember();

  const deleteMemberHandler = () => {
    deleteMemberMutation
      .mutateAsync({
        memberID: memberData.id,
      })
      .then(() =>
        fireNotification({
          title: "Member deleted successfully.",
          type: NOTIFICATIONS_TYPE.success,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
          target: NOTIFICATIONS_TARGET.everyone,
        })
      )
      .catch(() =>
        fireNotification({
          title: "An error occurred",
          description: "Please try again later",
          type: NOTIFICATIONS_TYPE.error,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
          target: NOTIFICATIONS_TARGET.everyone,
        })
      );
  };
  return (
    <GenericDialog
      dialogTitle="Delete member"
      dialogSubtitle={
        "Are you sure you want to delete this user? This action cannot be undone."
      }
      maxWidth="xs"
      triggerButton={
        <Tooltip title="Delete Member">
          <IconButton
            // onClick={(e) => e.stopPropagation()}
            sx={{ padding: "10px", borderRadius: "16px" }}
            aria-label="delete"
          >
            <DeleteIcon
              sx={{
                color: primaryColors.base.black,
                height: "20px",
                width: "20px",
              }}
            />
          </IconButton>
        </Tooltip>
      }
      triggerButtonDisplay="inline"
      submitButtonType={SUBMIT_BUTTON_TYPES.delete}
      submitButtonText={"Delete"}
      onSubmitCallback={deleteMemberHandler}
      closeButtonText={"Cancel"}
      stopPropagation
    />
  );
};
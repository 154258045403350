import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

const getComplaintsMetricsComparison = ({
  chartType,
  startDate,
  endDate,
  governorates,
  facilityTypes,
  branches,
  divisions,
  relatedTeams,
}) => {
  const requestURL = `${API_URL}/complaints-overview/metrics-comparison/${chartType}`;
  return melior.get(requestURL, {
    params: {
      date_from: startDate,
      date_to: endDate,
      ...(governorates?.length > 0 ? { governorates: governorates?.join(",") } : {}),
      ...(facilityTypes?.length > 0 ? { facility_types: facilityTypes?.join(",") } : {}),
      ...(branches?.length > 0 ? { branches: branches?.join(",") } : {}),
      ...(divisions?.length > 0 ? { divisions: divisions?.join(",") } : {}),
      ...(relatedTeams?.length > 0
        ? { related_teams: relatedTeams?.join(",") }
        : {}),
      compare_divisions: branches?.length === 1 ? true : false,
    },
  });
};

export const useGetComplaintsMetricsComparison = ({ config, params } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["complaints-metrics-comparison", params],
    queryFn: () => getComplaintsMetricsComparison(params),
    keepPreviousData: true,
  });
};

import { Box, Button, Typography } from "@mui/material";
import { PlatformsInsightsCards } from "./PlatformsInsightsCards";
import { RatingsComparisonBarChart } from "./RatingsComparisonBarChart";
import { ReputationComparisonTable } from "./ReputationComparisonTable";
import { primaryColors } from "../../../../helpers/customColors";
import { HashLink } from "react-router-hash-link";
import { fontWeightVariations } from "../../../../helpers/customFont";
import { AuthenticationContext } from "../../../login/contexts/AuthenticationContext";
import { useContext } from "react";

export const ReputationDashboard = ({ selectedFiltersState }) => {
  const { hasSignedInGoogle } = useContext(AuthenticationContext);

  return (
    <Box display="flex" flexDirection="column" gap="20px">
      {hasSignedInGoogle ? (
        <>
          <PlatformsInsightsCards
            startDate={selectedFiltersState.startDate}
            endDate={selectedFiltersState.endDate}
            governorates={selectedFiltersState.governorates}
            facilityTypes={selectedFiltersState.facilityTypes}
            branches={selectedFiltersState.branches}
            // relatedTeams={selectedFiltersState.selectedRelatedTeams}
          />
          <RatingsComparisonBarChart
            startDate={selectedFiltersState.startDate}
            endDate={selectedFiltersState.endDate}
            branches={selectedFiltersState.branches}
            governorates={selectedFiltersState.governorates}
            facilityTypes={selectedFiltersState.facilityTypes}
            // relatedTeams={selectedFiltersState.selectedRelatedTeams}
          />
          <ReputationComparisonTable
            startDate={selectedFiltersState.startDate}
            endDate={selectedFiltersState.endDate}
            branches={selectedFiltersState.branches}
            governorates={selectedFiltersState.governorates}
            facilityTypes={selectedFiltersState.facilityTypes}
            // relatedTeams={selectedFiltersState.selectedRelatedTeams}
          />
        </>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          borderRadius={3}
          boxShadow={2}
          padding={2}
          gap={2}
          bgcolor={primaryColors.base.white}
        >
          <Typography
            variant="text-lg"
            fontWeight={fontWeightVariations.medium}
            color={primaryColors.gray[900]}
          >
            You haven't registered any platforms yet
          </Typography>
          <HashLink
            title="Add Platform in Settings"
            to={"/settings/profile#platforms"}
            // style={{ color: COLORS.black }}
            smooth
          >
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: primaryColors.base.white,
                boxShadow: "none",
                //   width: "92px",
                height: "40px",
                borderRadius: "8px",
              }}
            >
              Add Platform
            </Button>
          </HashLink>
        </Box>
      )}
    </Box>
  );
};

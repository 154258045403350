import { Box } from "@mui/system";
import { DateRangePickerInput } from "../../../../reusable/components/DateRangePickerInput";
import moment from "moment";
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import {
  primaryColors,
  secondaryColors,
} from "../../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import { IsLoadingContainer } from "../../../../reusable/components/IsLoadingContainer";
import { NavLink } from "react-router-dom";

export const RevampedInsightsFilters = ({
  dateRangeFilter,
  governoratesFilter,
  facilityTypesFilter,
  branchesFilter,
  divisionsFilter,
  platformsFilter,
  relatedTeamsFilter,
}) => {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" alignItems="center" gap={3}>
        {/* Start: Date Range Filter */}
        <DateRangePickerInput
          callbackHandler={dateRangeFilter.onChange}
          resetDateHandler={dateRangeFilter.onReset}
          defaultDate={{
            startDate: dateRangeFilter?.selectedDateRange?.startDate
              ? moment(
                  dateRangeFilter.selectedDateRange.startDate,
                  "YYYY-MM-DD"
                ).toDate()
              : undefined,
            endDate: dateRangeFilter?.selectedDateRange?.endDate
              ? moment(
                  dateRangeFilter.selectedDateRange.endDate,
                  "YYYY-MM-DD"
                ).toDate()
              : undefined,
          }}
        />
        {/* End Date Range Filter */}

        {/* Start: Governorates Filter */}
        {governoratesFilter && governoratesFilter.options && (
          <FormControl
            sx={{ m: 1, minWidth: 200, position: "relative" }}
            focused={false}
          >
            <InputLabel
              sx={{
                position: "absolute",
                top:
                  governoratesFilter.selectedGovernorates.length === 0 &&
                  "-9px",
                fontSize: "0.9rem",
              }}
              id="select-governorates"
            >
              All Governorates
            </InputLabel>
            <Select
              labelId="select-governorates"
              sx={{ height: 35.5 }}
              multiple
              input={<OutlinedInput label={"All Governorates`"} />}
              name="governorates"
              value={governoratesFilter.selectedGovernorates}
              renderValue={(selected) => {
                return (
                  <Typography sx={{ fontSize: "0.9rem" }}>
                    {selected.length} Selected
                  </Typography>
                );
              }}
              onChange={governoratesFilter.onChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 320,
                  },
                },
                variant: "menu",
                getContentAnchorEl: null,
              }}
            >
              <MenuItem
                value={"all"}
                sx={{
                  background:
                    governoratesFilter.selectedGovernorates.length ===
                    governoratesFilter.options.length
                      ? primaryColors.brand[50]
                      : primaryColors.base.white,
                }}
              >
                <Checkbox
                  size="small"
                  checked={
                    governoratesFilter.selectedGovernorates.length ===
                    governoratesFilter.options.length
                  }
                />
                <ListItemText
                  primary="Show All"
                  sx={{
                    fontSize: "14px",
                  }}
                />
              </MenuItem>

              {governoratesFilter.options?.map(function renderGovernorateOption(
                governorateData,
                index
              ) {
                return (
                  <MenuItem
                    key={index}
                    value={governorateData.id}
                    sx={{
                      background: governorateData.highlight
                        ? primaryColors.brand[50]
                        : primaryColors.base.white,
                    }}
                  >
                    <Checkbox
                      size="small"
                      checked={governoratesFilter.selectedGovernorates?.includes(
                        governorateData.id
                      )}
                    />
                    <ListItemText
                      primary={governorateData.name}
                      sx={{
                        fontSize: "14px",
                      }}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
        {/* End: Governorates Filter */}

        {/* Start: Facility Types Filter */}
        {facilityTypesFilter && facilityTypesFilter.options && (
          <FormControl
            sx={{ m: 1, minWidth: 200, position: "relative" }}
            focused={false}
          >
            <InputLabel
              sx={{
                position: "absolute",
                top:
                  facilityTypesFilter.selectedFacilityTypes.length === 0 &&
                  "-9px",
                fontSize: "0.9rem",
              }}
              id="select-facilityTypes"
            >
              All Facility Types
            </InputLabel>
            <Select
              labelId="select-facilityTypes"
              sx={{ height: 35.5 }}
              multiple
              input={<OutlinedInput label={"All Facility Types`"} />}
              name="facility-types"
              value={facilityTypesFilter.selectedFacilityTypes}
              renderValue={(selected) => {
                return (
                  <Typography sx={{ fontSize: "0.9rem" }}>
                    {selected.length} Selected
                  </Typography>
                );
              }}
              onChange={facilityTypesFilter.onChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 320,
                  },
                },
                variant: "menu",
                getContentAnchorEl: null,
              }}
            >
              <MenuItem
                value={"all"}
                sx={{
                  background:
                    facilityTypesFilter.selectedFacilityTypes.length ===
                    facilityTypesFilter.options.length
                      ? primaryColors.brand[50]
                      : primaryColors.base.white,
                }}
              >
                <Checkbox
                  size="small"
                  checked={
                    facilityTypesFilter.selectedFacilityTypes.length ===
                    facilityTypesFilter.options.length
                  }
                />
                <ListItemText
                  primary="Show All"
                  sx={{
                    fontSize: "14px",
                  }}
                />
              </MenuItem>

              {facilityTypesFilter.options?.map(
                function renderGovernorateOption(governorateData, index) {
                  return (
                    <MenuItem
                      key={index}
                      value={governorateData.id}
                      sx={{
                        background: governorateData.highlight
                          ? primaryColors.brand[50]
                          : primaryColors.base.white,
                      }}
                    >
                      <Checkbox
                        size="small"
                        checked={facilityTypesFilter.selectedFacilityTypes?.includes(
                          governorateData.id
                        )}
                      />
                      <ListItemText
                        primary={governorateData.name}
                        sx={{
                          fontSize: "14px",
                        }}
                      />
                    </MenuItem>
                  );
                }
              )}
            </Select>
          </FormControl>
        )}
        {/* End: Facility Types Filter */}

        {/* Start: Branches Filter */}
        {branchesFilter && (
          <IsLoadingContainer isLoading={!branchesFilter?.options}>
            <FormControl
              sx={{ m: 1, minWidth: 200, position: "relative" }}
              focused={false}
            >
              <InputLabel
                sx={{
                  position: "absolute",
                  top: branchesFilter.selectedBranches.length === 0 && "-9px",
                  fontSize: "0.9rem",
                }}
                id="select-branches"
              >
                All Branches
              </InputLabel>
              <Select
                labelId="select-branches"
                sx={{ height: 35.5 }}
                multiple
                input={<OutlinedInput label={"All Branches`"} />}
                name="branches"
                value={branchesFilter.selectedBranches}
                renderValue={(selected) => {
                  return (
                    <Typography sx={{ fontSize: "0.9rem" }}>
                      {selected.length} Selected
                    </Typography>
                  );
                }}
                onChange={branchesFilter.onChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 320,
                    },
                  },
                  variant: "menu",
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem
                  value={"all"}
                  sx={{
                    background:
                      branchesFilter.selectedBranches.length ===
                      branchesFilter.options.length
                        ? primaryColors.brand[50]
                        : primaryColors.base.white,
                  }}
                >
                  <Checkbox
                    size="small"
                    checked={
                      branchesFilter.selectedBranches.length ===
                      branchesFilter.options.length
                    }
                  />
                  <ListItemText
                    primary="Show All"
                    sx={{
                      fontSize: "14px",
                    }}
                  />
                </MenuItem>

                {branchesFilter.options?.map(function renderBranchOption(
                  branchData,
                  index
                ) {
                  return (
                    <MenuItem
                      key={index}
                      value={branchData.id}
                      sx={{
                        background: branchData.highlight
                          ? primaryColors.brand[50]
                          : primaryColors.base.white,
                      }}
                    >
                      <Checkbox
                        size="small"
                        checked={branchesFilter.selectedBranches?.includes(
                          branchData.id
                        )}
                      />
                      <ListItemText
                        primary={branchData.name}
                        sx={{
                          fontSize: "14px",
                        }}
                      />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </IsLoadingContainer>
        )}
        {/* End: Branches Filter */}

        {/* Start: Divisions Filter */}
        {divisionsFilter && !divisionsFilter.hidden && (
          <IsLoadingContainer isLoading={!divisionsFilter?.options}>
            <FormControl
              sx={{ m: 1, minWidth: 200, position: "relative" }}
              focused={false}
            >
              <InputLabel
                sx={{
                  position: "absolute",
                  top: divisionsFilter.selectedDivisions.length === 0 && "-9px",
                  fontSize: "0.9rem",
                }}
                id="select-divisions"
              >
                All Divisions
              </InputLabel>
              <Select
                labelId="select-divisions"
                sx={{ height: 35.5 }}
                multiple
                input={<OutlinedInput label={"All Divisions"} />}
                name="divisions"
                value={divisionsFilter.selectedDivisions}
                renderValue={(selected) => {
                  return (
                    <Typography sx={{ fontSize: "0.9rem" }}>
                      {selected.length} Selected
                    </Typography>
                  );
                }}
                onChange={divisionsFilter.onChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 320,
                      // width: 200,
                    },
                  },
                  variant: "menu",
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem
                  value={"all"}
                  sx={{
                    background:
                      divisionsFilter.selectedDivisions.length ===
                      divisionsFilter.options.length
                        ? primaryColors.brand[50]
                        : primaryColors.base.white,
                  }}
                >
                  <Checkbox
                    size="small"
                    checked={
                      divisionsFilter.selectedDivisions.length ===
                      divisionsFilter.options.length
                    }
                  />
                  <ListItemText
                    primary="Show All"
                    sx={{
                      fontSize: "14px",
                    }}
                  />
                </MenuItem>
                {divisionsFilter.options?.map(function renderDivisionOption(
                  divisionData,
                  index
                ) {
                  return (
                    <MenuItem key={index} value={divisionData.id}>
                      <Checkbox
                        size="small"
                        checked={divisionsFilter.selectedDivisions?.includes(
                          divisionData.id
                        )}
                      />
                      <ListItemText
                        primary={divisionData.name}
                        sx={{
                          fontSize: "14px",
                        }}
                      />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </IsLoadingContainer>
        )}
        {/* End: Divisions Filter */}

        {/* Start: Platforms Filter */}
        {platformsFilter && (
          <FormControl
            sx={{ m: 1, minWidth: 200, position: "relative" }}
            focused={false}
          >
            <InputLabel
              sx={{
                position: "absolute",
                top: branchesFilter.selectedBranches?.length === 0 && "-9px",
                fontSize: "0.9rem",
              }}
              id="select-platforms"
            >
              All Platforms
            </InputLabel>
            <Select
              labelId="select-platforms"
              sx={{ height: 35.5 }}
              multiple
              input={<OutlinedInput label={"All Platforms`"} />}
              name="platforms"
              value={platformsFilter.selectedPlatforms}
              renderValue={(selected) => {
                return (
                  <Typography sx={{ fontSize: "0.9rem" }}>
                    {selected.length} Selected
                  </Typography>
                );
              }}
              onChange={function updateSelectedPlatforms(e) {
                if (!e.target.value?.includes(undefined))
                  platformsFilter.onChange(e);
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 320,
                    width: 200,
                  },
                },
                variant: "menu",
                getContentAnchorEl: null,
              }}
            >
              {platformsFilter.options?.map(function renderPlatformOption(
                platformData,
                index
              ) {
                return (
                  <MenuItem
                    key={index}
                    value={platformData.id}
                    sx={{
                      background: platformData.highlight
                        ? primaryColors.brand[50]
                        : primaryColors.base.white,
                    }}
                  >
                    <Checkbox
                      size="small"
                      checked={platformsFilter.selectedPlatforms?.includes(
                        platformData.id
                      )}
                    />
                    <ListItemText
                      primary={platformData.name}
                      sx={{
                        fontSize: "14px",
                      }}
                    />
                  </MenuItem>
                );
              })}
              <NavLink to="/settings/give-feedback">
                <Button
                  fullWidth
                  sx={{
                    borderTop: `1px solid ${primaryColors.gray[200]}`,
                  }}
                >
                  <Typography textTransform="none" fontSize="0.9rem">
                    Add Platform
                  </Typography>
                </Button>
              </NavLink>
            </Select>
          </FormControl>
        )}
        {/* End: Platform Filter */}

        {/* Start: Related Teams Filter */}
        {relatedTeamsFilter && !relatedTeamsFilter.hidden && (
          <IsLoadingContainer isLoading={!relatedTeamsFilter?.options}>
            <FormControl
              sx={{ m: 1, minWidth: 200, position: "relative" }}
              focused={false}
            >
              <InputLabel
                sx={{
                  position: "absolute",
                  top:
                    relatedTeamsFilter.selectedRelatedTeams.length === 0 &&
                    "-9px",
                  fontSize: "0.9rem",
                }}
                id="select-related-teams"
              >
                All Related Teams
              </InputLabel>
              <Select
                labelId="select-related-teams"
                sx={{ height: 35.5 }}
                multiple
                input={<OutlinedInput label={"All Related Teams`"} />}
                name="branches"
                value={relatedTeamsFilter.selectedRelatedTeams}
                renderValue={(selected) => {
                  return (
                    <Typography sx={{ fontSize: "0.9rem" }}>
                      {selected.length} Selected
                    </Typography>
                  );
                }}
                onChange={relatedTeamsFilter.onChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 320,
                    },
                  },
                  variant: "menu",
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem
                  value={"all"}
                  sx={{
                    background:
                      relatedTeamsFilter.selectedRelatedTeams.length ===
                      relatedTeamsFilter.options.length
                        ? primaryColors.brand[50]
                        : primaryColors.base.white,
                  }}
                >
                  <Checkbox
                    size="small"
                    checked={
                      relatedTeamsFilter.selectedRelatedTeams.length ===
                      relatedTeamsFilter.options.length
                    }
                  />
                  <ListItemText
                    primary="Show All"
                    sx={{
                      fontSize: "14px",
                    }}
                  />
                </MenuItem>

                {relatedTeamsFilter.options?.map(function renderBranchOption(
                  relatedTeamData,
                  index
                ) {
                  return (
                    <MenuItem
                      key={index}
                      value={relatedTeamData.id}
                      sx={{
                        background: relatedTeamData.highlight
                          ? primaryColors.brand[50]
                          : primaryColors.base.white,
                      }}
                    >
                      <Checkbox
                        size="small"
                        checked={relatedTeamsFilter.selectedRelatedTeams?.includes(
                          relatedTeamData.id
                        )}
                      />
                      <ListItemText
                        primary={relatedTeamData.name}
                        sx={{
                          fontSize: "14px",
                        }}
                      />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </IsLoadingContainer>
        )}
        {/* End: Related Teams Filter */}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        width="100%"
        gap={2}
      >
        {dateRangeFilter?.selectedDateRange?.startDate &&
          dateRangeFilter?.selectedDateRange?.endDate && (
            <Chip
              label={`From ${moment(
                dateRangeFilter?.selectedDateRange?.startDate
              ).format("DD/MMM, YYYY")} | To ${moment(
                dateRangeFilter?.selectedDateRange?.endDate
              ).format("DD/MMM, YYYY")}`}
              sx={{
                backgroundColor: secondaryColors.blue[50],
                color: secondaryColors.blue[700],
                fontSize: fontSizeVariations["text-sm"],
                fontWeight: fontWeightVariations.medium,
              }}
              onDelete={dateRangeFilter?.onReset}
            />
          )}
        {/* start: selected governorates badges */}
        {governoratesFilter?.selectedGovernorates &&
          governoratesFilter?.selectedGovernorates?.length !== 0 &&
          governoratesFilter?.selectedGovernorates.map(
            function renderFilterChipForGovernorate(governorateID, index) {
              return (
                <Chip
                  key={index}
                  label={
                    governoratesFilter.options.find(
                      function isGovernorateIdMatching(governorateOption) {
                        return governorateOption.id === governorateID;
                      }
                    )?.name
                  }
                  sx={{
                    backgroundColor: secondaryColors.blue[50],
                    color: secondaryColors.blue[700],
                    ...fontSizeVariations["text-sm"],
                    fontWeight: fontWeightVariations.medium,
                  }}
                  onDelete={function removeGovernorateOnClick() {
                    governoratesFilter.removeSpecificGovernorate(governorateID);
                  }}
                />
              );
            }
          )}
        {/* end: selected governorates badges */}
        {/* start: selected facility types badges */}
        {facilityTypesFilter?.selectedFacilityTypes &&
          facilityTypesFilter?.selectedFacilityTypes?.length !== 0 &&
          facilityTypesFilter?.selectedFacilityTypes.map(
            function renderFilterChipForFacilityType(facilityTypeID, index) {
              return (
                <Chip
                  key={index}
                  label={
                    facilityTypesFilter.options.find(
                      function isFacilityTypeIdMatching(facilityTypeOption) {
                        return facilityTypeOption.id === facilityTypeID;
                      }
                    )?.name
                  }
                  sx={{
                    backgroundColor: secondaryColors.blue[50],
                    color: secondaryColors.blue[700],
                    ...fontSizeVariations["text-sm"],
                    fontWeight: fontWeightVariations.medium,
                  }}
                  onDelete={function removeFacilityTypeOnClick() {
                    facilityTypesFilter.removeSpecificFacilityType(
                      facilityTypeID
                    );
                  }}
                />
              );
            }
          )}
        {/* end: selected facilityTypes badges */}
        {/* start: selected branches badges */}
        {branchesFilter?.selectedBranches &&
          branchesFilter?.selectedBranches?.length !== 0 &&
          branchesFilter?.selectedBranches.map(
            function renderFilterChipForBranch(branchID, index) {
              return (
                <Chip
                  key={index}
                  label={
                    branchesFilter.options.find(function isBranchIdMatching(
                      branchOption
                    ) {
                      return branchOption.id === branchID;
                    })?.name
                  }
                  sx={{
                    backgroundColor: secondaryColors.blue[50],
                    color: secondaryColors.blue[700],
                    ...fontSizeVariations["text-sm"],
                    fontWeight: fontWeightVariations.medium,
                  }}
                  onDelete={function removeBranchOnClick() {
                    branchesFilter.removeSpecificBranch(branchID);
                  }}
                />
              );
            }
          )}
        {/* end: selected branches badges */}
        {/* start: selected divisions badges */}
        {divisionsFilter?.selectedDivisions &&
          divisionsFilter?.selectedDivisions?.length !== 0 &&
          divisionsFilter?.selectedDivisions.map(
            function renderFilterChipForBranch(divisionID, index) {
              return (
                <Chip
                  key={index}
                  label={
                    divisionsFilter.options.find(function isDivisionIdMatching(
                      divisionOption
                    ) {
                      return divisionOption.id === divisionID;
                    })?.name
                  }
                  sx={{
                    backgroundColor: secondaryColors.blue[50],
                    color: secondaryColors.blue[700],
                    ...fontSizeVariations["text-sm"],
                    fontWeight: fontWeightVariations.medium,
                  }}
                  onDelete={function removeDivsionOnClick() {
                    divisionsFilter.removeSpecificDivision(divisionID);
                  }}
                />
              );
            }
          )}
        {/* end: selected divisions badges */}
      </Box>
    </Box>
  );
};

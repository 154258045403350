import { DistributionPage } from "../components/DistributionPage";
import { ScreenContainer } from "../../../layout/ScreenContainer";

export const DistributionScreen = () => {
  return (
    <ScreenContainer>
      <DistributionPage />
    </ScreenContainer>
  );
};

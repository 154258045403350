import moment from "moment";
import { useReducer } from "react";
import { channelsDict } from "./Filtration";
import { ReputationResponseStatuses } from "../../types/reputation-reponses";

export const useOverallViewFiltersState = () => {
  const [selectedFiltersState, dispatchActionToFilters] = useReducer(
    overallViewFiltersReducer,
    overallViewSelecterFiltersInitialState
  );

  function updateSelectedDivisions(event) {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["UPDATE-SELECTED-DIVISIONS"],
      payload: { divisions: event.target.value },
    });
  }

  function updateSelectedBranches(event) {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["UPDATE-SELECTED-BRANCHES"],
      payload: { branches: event.target.value },
    });
  }

  function updateSelectedDateRange({ from, to }) {
    const fromDate = moment(from).toISOString(true).split("T")[0];
    const toDate = moment(to).toISOString(true).split("T")[0];

    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["UPDATE-DATE-RANGE"],
      payload: { date_from: fromDate, date_to: toDate },
    });
  }

  function resetDateRange() {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["RESET-SELECTED-DATE"],
    });
  }

  function resetAllFiltersState() {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["RESET-ALL-FILTERS"],
    });
  }

  function resetSearchFilterState() {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["RESET-SEARCH-FILTER"],
    });
  }

  function updateSelectedGovernorates(event) {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["UPDATE-SELECTED-GOVERNORATES"],
      payload: {
        governorates: event.target.value,
      },
    });
  }
  function removeSpecificGovernorate(governorateIDToRemove) {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["REMOVE-SELECTED-GOVERNORATE"],
      payload: { governorateID: governorateIDToRemove },
    });
  }

  
  function updateSelectedFacilityTypes(event) {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["UPDATE-SELECTED-FACILITY-TYPES"],
      payload: {
        facilityTypes: event.target.value,
      },
    });
  }
  function removeSpecificFacilityType(facilityTypeIDToRemove) {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["REMOVE-SELECTED-FACILITY-TYPE"],
      payload: { facilityTypeID: facilityTypeIDToRemove },
    });
  }

  function updateSelectedChannels(event) {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["UPDATE-SELECTED-CHANNELS"],
      payload: { channels: event.target.value },
    });
  }

  function removeSpecificDivision(divisionIDToRemove) {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["REMOVE-SELECTED-DIVISION"],
      payload: { divisionID: divisionIDToRemove },
    });
  }

  function removeSpecificBranch(branchIDToRemove) {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["REMOVE-SELECTED-BRANCH"],
      payload: { branchID: branchIDToRemove },
    });
  }

  function removeSpecificChannel(channelToRemove) {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["REMOVE-SELECTED-CHANNEL"],
      payload: { channel: channelToRemove },
    });
  }

  function updateSelectedQuestions(event) {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["UPDATE-SELECTED-QUESTIONS"],
      payload: {
        questions: event.target.value,
      },
    });
  }
  function removeSpecificQuestion(questionIDToRemove) {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["REMOVE-SELECTED-QUESTION"],
      payload: { questionID: questionIDToRemove },
    });
  }

  function updateWithCommentFilterState(withCommentNewFilterState) {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions[
        "UPDATE-WITH-COMMENT-FILTER-STATE"
      ],
      payload: {
        withComment: withCommentNewFilterState,
      },
    });
  }

  function updateComplaintStatusFilterState(complaintNewStatusValue) {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["UPDATE-COMPLAINT-STATUS-FILTER"],
      payload: {
        complaintStatus: complaintNewStatusValue,
      },
    });
  }

  function updateReputationResponseStatusFilterState(
    reputationResponseNewStatus
  ) {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions[
        "UPDATE-REPUTATION-RESPONSE-STATUS-FILTER"
      ],
      payload: {
        reputationResponseStatus: reputationResponseNewStatus,
      },
    });
  }

  function updateComplaintsAssignees(event) {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["UPDATE-COMPLAINT-ASSIGNEES"],
      payload: {
        complaintsAssignees: event.target.value,
      },
    });
  }

  function removeSpecificComplaintAssignee(assigneeIDToRemove) {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["REMOVE-COMPLAINT-ASSIGNEE"],
      payload: { complaintAssigneeID: assigneeIDToRemove },
    });
  }

  function updateArchivedFilterState(archivedNewFilterState) {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["UPDATE-ARCHIVED-FILTER-STATE"],
      payload: {
        archived: archivedNewFilterState,
      },
    });
  }

  function updateBookmarkedFilterState(bookmarkedNewFilterState) {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["UPDATE-BOOKMARKED-FILTER-STATE"],
      payload: {
        bookmarked: bookmarkedNewFilterState,
      },
    });
  }

  function updateReputationResponsePlatformFilterState(
    newReputationResponsePlatform
  ) {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions[
        "UPDATE-REPUTATION-RESPONSE-PLATFORM-FILTER-STATE"
      ],
      payload: {
        reputationResponsePlatform: newReputationResponsePlatform,
      },
    });
  }

  function updateReputationResponseRatingFilterState(
    newReputationResponseRating
  ) {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions[
        "UPDATE-REPUTATION-RESPONSE-RATING-FILTER-STATE"
      ],
      payload: {
        reputationResponseRating: newReputationResponseRating,
      },
    });
  }

  function updateSelectedRelatedTeams(event) {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["UPDATE-SELECTED-RELATED-TEAMS"],
      payload: {
        selectedRelatedTeams: event.target.value,
      },
    });
  }

  function removeSpecificRelatedTeam(relatedTeamIDToRemove) {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["REMOVE-RELATED-TEAM"],
      payload: { relatedTeamIDToRemove: relatedTeamIDToRemove },
    });
  }

  return {
    selectedFiltersState,
    dispatchActionToFilters,
    updateSelectedGovernorates,
    removeSpecificGovernorate,
    updateSelectedFacilityTypes,
    removeSpecificFacilityType,
    updateSelectedDivisions,
    updateSelectedBranches,
    updateSelectedDateRange,
    updateSelectedQuestions,
    resetDateRange,
    updateSelectedChannels,
    resetSearchFilterState,
    resetAllFiltersState,
    removeSpecificDivision,
    removeSpecificBranch,
    removeSpecificChannel,
    removeSpecificQuestion,
    updateWithCommentFilterState,
    updateComplaintStatusFilterState,
    updateReputationResponseStatusFilterState,
    updateComplaintsAssignees,
    removeSpecificComplaintAssignee,
    updateArchivedFilterState,
    updateBookmarkedFilterState,
    updateReputationResponsePlatformFilterState,
    updateReputationResponseRatingFilterState,
    updateSelectedRelatedTeams,
    removeSpecificRelatedTeam,
  };
};

export const complaintStatus = {
  All: undefined,
  Unattended: "unattended",
  Seen: "seen",
  Resolved: "resolved",
};

export const overallViewSelecterFiltersInitialState = {
  // Default Date Range is the past Week
  date_from: moment().subtract(7, "days").format("YYYY-MM-DD"),
  date_to: moment().format("YYYY-MM-DD"),
  governorates: [],
  facilityTypes: [],
  divisions: [],
  branches: [],
  questions: [],
  channels: [],
  complaintsAssignees: [],
  selectedRelatedTeams: [],
  searchText: "",
  searchType: "",
  withComment: undefined,
  complaintStatus: complaintStatus.All,
  reputationResponseStatus: ReputationResponseStatuses.All,
  bookmarked: undefined,
  archived: undefined,
  reputationResponsePlatform: undefined,
  reputationResponseRating: undefined,
};

const overallViewFiltersReducer = (state, action) => {
  switch (action.type) {
    case overallViewFiltersDispatchActions["UPDATE-DATE-RANGE"]: {
      return {
        ...state,
        date_from: action.payload.date_from,
        date_to: action.payload.date_to,
      };
    }
    case overallViewFiltersDispatchActions["RESET-SELECTED-DATE"]: {
      return {
        ...state,
        date_from: overallViewSelecterFiltersInitialState.date_from,
        date_to: overallViewSelecterFiltersInitialState.date_to,
      };
    }
    case overallViewFiltersDispatchActions["RESET-SEARCH-FILTER"]: {
      return {
        ...state,
        searchType: overallViewSelecterFiltersInitialState.searchType,
        searchText: overallViewSelecterFiltersInitialState.searchText,
      };
    }
    case overallViewFiltersDispatchActions["RESET-ALL-FILTERS"]: {
      return {
        ...overallViewSelecterFiltersInitialState,
      };
    }
    case overallViewFiltersDispatchActions["UPDATE-SELECTED-GOVERNORATES"]: {
        return {
          ...state,
          governorates: action.payload.governorates,
        };
      }
      case overallViewFiltersDispatchActions["REMOVE-SELECTED-GOVERNORATE"]: {
        return {
          ...state,
          governorates: [
            ...state.governorates.filter(function filterOutSpecificGovernorate(
              existingGovernorateID
            ) {
              return existingGovernorateID !== action.payload.governorateID;
            }),
          ],
        };
      }
      case overallViewFiltersDispatchActions["UPDATE-SELECTED-FACILITY-TYPES"]: {
        return {
          ...state,
          facilityTypes: action.payload.facilityTypes,
        };
      }
      case overallViewFiltersDispatchActions["REMOVE-SELECTED-FACILITY-TYPE"]: {
        return {
          ...state,
          facilityTypes: [
            ...state.facilityTypes.filter(function filterOutSpecificFacilityType(
              existingFacilityTypeID
            ) {
              return existingFacilityTypeID !== action.payload.facilityTypeID;
            }),
          ],
        };
      }
    case overallViewFiltersDispatchActions["UPDATE-SELECTED-DIVISIONS"]: {
      return {
        ...state,
        divisions: action.payload.divisions,
      };
    }
    case overallViewFiltersDispatchActions["REMOVE-SELECTED-DIVISION"]: {
      return {
        ...state,
        divisions: [
          ...state.divisions.filter(function filterOutSpecificDivison(
            existingDivisonID
          ) {
            return existingDivisonID !== action.payload.divisionID;
          }),
        ],
      };
    }
    case overallViewFiltersDispatchActions["UPDATE-SELECTED-BRANCHES"]: {
      return {
        ...state,
        branches: action.payload.branches,
      };
    }
    case overallViewFiltersDispatchActions["REMOVE-SELECTED-BRANCH"]: {
      return {
        ...state,
        branches: [
          ...state.branches.filter(function isNotBranchToBeRemoved(
            existingBranchID
          ) {
            return existingBranchID !== action.payload.branchID;
          }),
        ],
      };
    }
    case overallViewFiltersDispatchActions["UPDATE-SELECTED-QUESTIONS"]: {
      return {
        ...state,
        questions: action.payload.questions,
      };
    }
    case overallViewFiltersDispatchActions["REMOVE-SELECTED-QUESTION"]: {
      return {
        ...state,
        questions: [
          ...state.questions.filter(function filterOutSpecificQuestion(
            existingQuestionID
          ) {
            return existingQuestionID !== action.payload.questionID;
          }),
        ],
      };
    }
    case overallViewFiltersDispatchActions["REMOVE-SELECTED-CHANNEL"]: {
      return {
        ...state,
        channels: [
          ...state.channels.filter(function filterOutSpecificChannel(
            existingChannel
          ) {
            return existingChannel !== action.payload.channel;
          }),
        ],
      };
    }
    case overallViewFiltersDispatchActions["UPDATE-SELECTED-CHANNELS"]: {
      return {
        ...state,
        channels: action.payload.channels.includes(channelsDict.All)
          ? []
          : action.payload.channels,
      };
    }
    case overallViewFiltersDispatchActions["UPDATE-COMPLAINT-ASSIGNEES"]: {
      return {
        ...state,
        complaintsAssignees: action.payload.complaintsAssignees.includes("")
          ? []
          : action.payload.complaintsAssignees,
      };
    }
    case overallViewFiltersDispatchActions["REMOVE-COMPLAINT-ASSIGNEE"]: {
      return {
        ...state,
        complaintsAssignees: [
          ...state.complaintsAssignees.filter(
            function filterOutSpecificAssignee(existingAssigneeID) {
              return existingAssigneeID !== action.payload.complaintAssigneeID;
            }
          ),
        ],
      };
    }
    case overallViewFiltersDispatchActions[
      "UPDATE-WITH-COMMENT-FILTER-STATE"
    ]: {
      return {
        ...state,
        withComment: action.payload.withComment,
      };
    }
    case overallViewFiltersDispatchActions["UPDATE-COMPLAINT-STATUS-FILTER"]: {
      return {
        ...state,
        complaintStatus: action.payload.complaintStatus,
      };
    }
    case overallViewFiltersDispatchActions[
      "UPDATE-REPUTATION-RESPONSE-STATUS-FILTER"
    ]: {
      return {
        ...state,
        reputationResponseStatus: action.payload.reputationResponseStatus,
      };
    }
    case overallViewFiltersDispatchActions["UPDATE-ARCHIVED-FILTER-STATE"]: {
      return {
        ...state,
        archived: action.payload.archived,
      };
    }
    case overallViewFiltersDispatchActions["UPDATE-BOOKMARKED-FILTER-STATE"]: {
      return {
        ...state,
        bookmarked: action.payload.bookmarked,
      };
    }
    case overallViewFiltersDispatchActions[
      "UPDATE-REPUTATION-RESPONSE-PLATFORM-FILTER-STATE"
    ]: {
      return {
        ...state,
        reputationResponsePlatform: action.payload.reputationResponsePlatform,
      };
    }
    case overallViewFiltersDispatchActions[
      "UPDATE-REPUTATION-RESPONSE-RATING-FILTER-STATE"
    ]: {
      return {
        ...state,
        reputationResponseRating: action.payload.reputationResponseRating,
      };
    }
    case overallViewFiltersDispatchActions["UPDATE-SELECTED-RELATED-TEAMS"]: {
      return {
        ...state,
        selectedRelatedTeams: action.payload.selectedRelatedTeams.includes("")
          ? []
          : action.payload.selectedRelatedTeams,
      };
    }
    case overallViewFiltersDispatchActions["REMOVE-RELATED-TEAM"]: {
      return {
        ...state,
        selectedRelatedTeams: [
          ...state.selectedRelatedTeams.filter(function filterOutSpecificRelatedTeam(
            existingRelatedTeamID
          ) {
            return (
              existingRelatedTeamID !== action.payload.relatedTeamIDToRemove
            );
          }),
        ],
      };
    }
    default: {
      return state;
    }
  }
};

export const overallViewFiltersDispatchActions = {
    "RESET-ALL-FILTERS": "RESET-ALL-FILTERS",

  "UPDATE-DATE-RANGE": "UPDATE-DATE-RANGE",
  "RESET-SELECTED-DATE": "RESET-SELECTED-DATE",

  "RESET-SEARCH-FILTER": "RESET-SEARCH-FILTER",

  "UPDATE-SELECTED-DIVISIONS": "UPDATE-SELECTED-DIVISIONS",
  "REMOVE-SELECTED-DIVISION": "REMOVE-SELECTED-DIVISION",

  "UPDATE-SELECTED-BRANCHES": "UPDATE-SELECTED-BRANCHES",
  "REMOVE-SELECTED-BRANCH": "REMOVE-SELECTED-BRANCH",

  "UPDATE-SELECTED-CHANNELS": "UPDATE-SELECTED-CHANNELS",
  "REMOVE-SELECTED-CHANNEL": "REMOVE-SELECTED-CHANNEL",

  "UPDATE-SELECTED-QUESTIONS": "UPDATE-SELECTED-QUESTIONS",
  "REMOVE-SELECTED-QUESTION": "REMOVE-SELECTED-QUESTION",

  "UPDATE-SELECTED-GOVERNORATES": "UPDATE-SELECTED-GOVERNORATES",
  "REMOVE-SELECTED-GOVERNORATE": "REMOVE-SELECTED-GOVERNORATE",

  "UPDATE-SELECTED-FACILITY-TYPES": "UPDATE-SELECTED-FACILITY-TYPES",
  "REMOVE-SELECTED-FACILITY-TYPE": "REMOVE-SELECTED-FACILITY-TYPE",
  
  "UPDATE-WITH-COMMENT-FILTER-STATE": "UPDATE-WITH-COMMENT-FILTER-STATE",

  "UPDATE-COMPLAINT-STATUS-FILTER": "UPDATE-COMPLAINT-STATUS-FILTER",
  "UPDATE-COMPLAINT-ASSIGNEES": "UPDATE-COMPLAINT-ASSIGNEES",
  "REMOVE-COMPLAINT-ASSIGNEE": "REMOVE-COMPLAINT-ASSIGNEE",

  "UPDATE-SEARCH-DATA": "UPDATE-SEARCH-DATA",
  "UPDATE-ARCHIVED-FILTER-STATE": "UPDATE-ARCHIVED-FILTER-STATE",
  "UPDATE-BOOKMARKED-FILTER-STATE": "UPDATE-BOOKMARKED-FILTER-STATE",

  "UPDATE-REPUTATION-RESPONSE-STATUS-FILTER":
    "UPDATE-REPUTATION-RESPONSE-STATUS-FILTER",
  "UPDATE-REPUTATION-RESPONSE-RATING-FILTER-STATE":
    "UPDATE-REPUTATION-RESPONSE-RATING-FILTER-STATE",
  "UPDATE-REPUTATION-RESPONSE-PLATFORM-FILTER-STATE":
    "UPDATE-REPUTATION-RESPONSE-PLATFORM-FILTER-STATE",

  "UPDATE-SELECTED-RELATED-TEAMS": "UPDATE-SELECTED-RELATED-TEAMS",
  "REMOVE-RELATED-TEAM": "REMOVE-RELATED-TEAM",
};

import { Box, Button } from "@mui/material";
import { ReviewsTable, useReviewsTableColumns } from "./ReviewsTable";
import { Filtration } from "../shared/Filtration";
import { useOverallViewFiltersState } from "../shared/useOverallViewFiltersState";
import { useGetFacilityTemplates } from "../../api/getFacilityTemplates";
import { useSearchParams } from "react-router-dom";
import { TableContainer } from "../shared/TableContainer";
import { ReviewTableRowDetails } from "./ReviewTableRowDetails";
import { useGetReviewDetails } from "../../api/reviews/getReviewDetails";
import { OverallViewTable } from "../shared/OverallViewTable";
import { useGetReviews } from "../../api/reviews/getReviews";
import { searchTypes } from "../shared/DynamicSearchInput";
import { Link } from "react-router-dom";
import { primaryColors } from "../../../../helpers/customColors";
import { useGetAllQuestions } from "../../api/reviews/getAllQuestions";
import SaveAs from "./SaveAs";
import { useEffect, useState } from "react";

export const ReviewsTables = ({ starred, archived }) => {
  const {
    selectedFiltersState,
    updateSelectedGovernorates,
    removeSpecificGovernorate,
    updateSelectedFacilityTypes,
    removeSpecificFacilityType,
    updateSelectedDivisions,
    updateSelectedDateRange,
    resetDateRange,
    updateSelectedChannels,
    removeSpecificDivision,
    removeSpecificChannel,
    updateSelectedQuestions,
    removeSpecificQuestion,
    updateWithCommentFilterState,
    updateArchivedFilterState,
    updateBookmarkedFilterState,
  } = useOverallViewFiltersState();

  const { data: facilityTemplatesResponse } = useGetFacilityTemplates();
  const { data: allQuestionsResponse } = useGetAllQuestions();
  console.log(selectedFiltersState, "??????????");
  const dateFrom = selectedFiltersState.date_from;
  const dateTo = selectedFiltersState.date_to;
  const selectedGovernorate = selectedFiltersState.governorates.join(",");
  const selectedFacilityTypes = selectedFiltersState.facilityTypes.join(",");
  const selectedDivisions = selectedFiltersState.divisions.join(",");
  const selectedChannels = selectedFiltersState.channels.join(",");
  const selectedQuestions = selectedFiltersState.questions.join(",");

  const appliedFiltersForReviewsTables = {
    search_text: undefined,
    search_type: undefined,
    date_from: dateFrom,
    date_to: dateTo,
    governorates: selectedGovernorate,
    facility_types: selectedFacilityTypes,
    divisions: selectedDivisions,
    channels: selectedChannels,
    questions: selectedQuestions,
    comment_text: selectedFiltersState.withComment,
    bookmarked: starred,
    archived: archived,
  };

  // Start: Related to Single Review View
  let [searchParams] = useSearchParams();
  const { reviewsTableColumns } = useReviewsTableColumns();

  const reviewID = searchParams.get("reviewID");

  const singleReviewQueryParams = {
    page: 1,
  };

  singleReviewQueryParams[searchTypes.id.key] = reviewID;

  const {
    data: singleReviewSearchResultResponse,
    isFetching: isFetchingReviews,
    isError: reviewsFetchingError,
    isLoading: isLoadingReviews,
  } = useGetReviews({
    params: { ...singleReviewQueryParams },
    config: { enabled: reviewID !== null },
  });
  const singleReviewView =
    singleReviewSearchResultResponse === undefined ? null : (
      <TableContainer label={`Review ID #${reviewID}`}>
        <OverallViewTable
          columns={reviewsTableColumns}
          data={singleReviewSearchResultResponse.data.results}
          totalRecords={singleReviewSearchResultResponse?.data.total_count}
          isLoading={isLoadingReviews}
          isError={reviewsFetchingError}
          externalPaginationProps={{
            externalCurrentPage: 1,
            updateExternalCurrentPage: undefined,
            isFetching: isFetchingReviews,
          }}
          renderRowDetails={({ row }) => {
            return (
              <ReviewTableRowDetails
                reviewDetails={row.original}
                isExpanded={row.getIsExpanded()}
              />
            );
          }}
          expandAllByDefault
        />
      </TableContainer>
    );
  // End: Related to Single Review View

  // Start: Related to Multiple Reviews View
  const [reviewsTables, setReviewsTables] = useState([]);

  const moveReviewTable = ({ currentIndex, direction }) => {
    const newIndex = currentIndex + direction;
    if (newIndex >= 0 && newIndex < reviewsTables.length) {
      const updatedReviewsTables = [...reviewsTables];
      const currentReviewTable = updatedReviewsTables[currentIndex];
      const reviewTableToSwap = updatedReviewsTables[newIndex];
      updatedReviewsTables[currentIndex] = reviewTableToSwap;
      updatedReviewsTables[newIndex] = currentReviewTable;
      setReviewsTables(updatedReviewsTables);
    }
  };

  const reviewsTablesForTemplatesView = !reviewsTables
    ? null
    : reviewsTables?.map(function renderReviewTable(templateData, index) {
        return (
          <ReviewsTable
            templateData={templateData}
            selectedFiltersState={selectedFiltersState}
            starred={starred}
            archived={archived}
            key={index}
            moveUp={
              index === 0
                ? undefined
                : function moveTableUp() {
                    moveReviewTable({ currentIndex: index, direction: -1 });
                  }
            }
            moveDown={
              index === reviewsTables.length - 1
                ? undefined
                : function moveTableDown() {
                    moveReviewTable({ currentIndex: index, direction: 1 });
                  }
            }
          />
        );
      });
  // End: Related to Multiple Reviews View

  const showSingleReviewView =
    singleReviewSearchResultResponse && reviewID !== null;

  useEffect(
    function setReviewData() {
      setReviewsTables(facilityTemplatesResponse?.data);
    },
    [facilityTemplatesResponse]
  );

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        {/* Hide reviews filtration if we're at a single review view */}
        {!reviewID && (
          <Filtration
            selectedFiltersState={selectedFiltersState}
            dateRangeFilter={{
              selectedDateRange: {
                date_from: selectedFiltersState.date_from,
                date_to: selectedFiltersState.date_to,
              },
              onChange: updateSelectedDateRange,
              resetDateRange,
            }}
            branchesAndDivisionsFilter={{
              selectedDivisions: selectedFiltersState.divisions,
              onChange: updateSelectedDivisions,
              removeSpecificDivision,
            }}
            governoratesFilter={{
              selectedGovernorates: selectedFiltersState.governorates,
              onChange: updateSelectedGovernorates,
              removeSpecificGovernorate: removeSpecificGovernorate,
            }}
            facilityTypesFilter={{
              selectedFacilityTypes: selectedFiltersState.facilityTypes,
              onChange: updateSelectedFacilityTypes,
              removeSpecificFacilityType: removeSpecificFacilityType,
            }}
            channelsFilter={{
              selectedChannels: selectedFiltersState.channels,
              onChange: updateSelectedChannels,
              removeSpecificChannel,
            }}
            questionsFilter={{
              selectedQuestions: selectedFiltersState.questions,
              onChange: updateSelectedQuestions,
              removeSpecificQuestion: removeSpecificQuestion,
            }}
            withCommentFilter={{
              withComment: selectedFiltersState.withComment,
              onChange: updateWithCommentFilterState,
            }}
            archivedFilter={{
              archived: selectedFiltersState.archived,
              onChange: updateArchivedFilterState,
            }}
            bookmarkedFilter={{
              bookmarked: selectedFiltersState.bookmarked,
              onChange: updateBookmarkedFilterState,
            }}
          />
        )}

        {allQuestionsResponse && (
          <SaveAs
            queryStringsForAPI={{ ...appliedFiltersForReviewsTables }}
            selectedReviewsIDs={[]}
            label="Export All"
            questionsDataToRender={
              allQuestionsResponse.data.find(function getEnglishQuestions(
                questionsLanguageData
              ) {
                return questionsLanguageData.lang === "en";
              }).questions
            }
          />
        )}

        {reviewID && (
          <Link to={"/overall-view/reviews"}>
            <Button variant="outlined" sx={{ textTransform: "capitalize" }}>
              Show All Templates Reviews
            </Button>
          </Link>
        )}
      </Box>

      {showSingleReviewView ? singleReviewView : reviewsTablesForTemplatesView}
    </Box>
  );
};
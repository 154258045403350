import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const deleteInvitedMember = ({ memberID }) => {
  const requestURL = `${API_URL}/users-invitations/${memberID}`;
  return melior.delete(requestURL);
};

export const useDeleteInvitedMember = ({ config } = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: () => {
        queryClient.invalidateQueries(["invited-members"], { type: "active" });
    },
    ...config,
    mutationFn: deleteInvitedMember,
  });
};
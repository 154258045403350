import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import { primaryColors } from "../../../helpers/customColors";
import { RevampedLandingPageCard } from "./RevampedLandingPage";
import { HashLink } from "react-router-hash-link";
import { getNavigatableSectionSlug } from "../../../helpers/General";
import { MenuOpen } from "@mui/icons-material";
import {
  RevampedVerticalBarChart,
  PositiveNegativeBarChart,
} from "../../../reusable/components/charts";
import { useCallback, useEffect, useState } from "react";
import { getBranchesBarCharComparisonData } from "../api/getBranchesBarCharComparisonData";
import { fontWeightVariations } from "../../../helpers/customFont";
import { useDynamicFiltersState } from "../../tabbed-landing-page/hooks/useDynamicFiltersState";
import { DynamicFilters } from "../../tabbed-landing-page/components/shared/DynamicFilters";

export const AllBranchesQuestionBarChart = ({
  startDate,
  endDate,
  governorates,
  facilityTypes,
  branches,
  divisions,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [branchesComparisonData, setBranchesComparisonData] = useState([]);

  const getFiltersData = useCallback(function prepareFiltersData() {
    return [
      { dropdownName: "insightType", dropdownOptions: insightsOptions },
    //   {
    //     dropdownName: "compare",
    //     disableDropdown: true,
    //     dropdownOptions: compareOptions,
    //   },
    ];
  }, []);

  const { selectedFiltersState, updateSelectedFilters, replaceFilterState } =
    useDynamicFiltersState({
      isEachFilterIndependent: true,
      serverFiltersData: getFiltersData(),
      initialFiltersState: {
        insightType: 0,
        compare: 0,
      },
    });

  function fetchBranchesComparisonData({
    startDate,
    endDate,
    governorates,
    facilityTypes,
    branches,
    divisions,
    chartType,
  }) {
    setIsFetching(true);
    
    getBranchesBarCharComparisonData({
      startDate,
      endDate,
      governorates,
      facilityTypes,
      branches,
      divisions,
      chartType,
    }).then(function setBranchesData(response) {
      setBranchesComparisonData(response.data);
      setIsFetching(false);
    });
  }
  // useEffect(
  //   // to update disableDropdown condition
  //   function updateFiltersData() {
  //     replaceFilterState({ newFiltersData: getFiltersData() });
  //   },
  //   [getFiltersData, replaceFilterState]
  // );
  useEffect(
    function updateSelectedOptionForCompareDropdown() {
      let compareSelectedValue;
      const multipleBranchesSelected =
        branches.length !== 1 && divisions.length === 0;
      compareSelectedValue = multipleBranchesSelected
        ? compareOptions[0].value
        : compareOptions[1].value;
      updateSelectedFilters({
        target: { name: "compare", value: compareSelectedValue },
      });
    },
    [branches, divisions]
  );

  useEffect(
    function getNPSChartData() {
      fetchBranchesComparisonData({
        startDate,
        endDate,
        governorates,
        facilityTypes,
        branches,
        divisions,
        chartType: insightsOptions[selectedFiltersState.insightType].type,
      });
    },
    [startDate, endDate, branches, divisions, selectedFiltersState.insightType, governorates, facilityTypes]
  );

  return (
    <RevampedLandingPageCard
      showSeparator={true}
      labelHeight="30px"
      label={
        <Typography
          variant="text-lg"
          fontWeight={fontWeightVariations.medium}
          color={primaryColors.gray[900]}
        >
          Standard Metrics Comparison
        </Typography>
      }
    >
      <Box display="flex" flexDirection="column" height="400px" gap={2}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <DynamicFilters
            selectedFiltersState={selectedFiltersState}
            updateSelectedFilters={updateSelectedFilters}
          />
          <Box display="flex" flexDirection="row">
            {/* Start: Branches Colors Hint */}
            {selectedFiltersState.insightType === 1 && (
              <Box
                display="flex"
                gap="20px"
                width="100%"
                justifyContent="center"
              >
                {Object.keys(averageSatisfactionColors).map(
                  function renderChoicesLegend(insightColorName, index) {
                    return (
                      <Box
                        key={index}
                        display="flex"
                        alignItems="center"
                        gap={1}
                      >
                        <Box
                          width="12px"
                          height="12px"
                          bgcolor={averageSatisfactionColors[insightColorName]}
                          display="inline-block"
                          borderRadius="50%"
                        ></Box>
                        <Typography variant="text-xs">
                          {insightColorName}
                        </Typography>
                      </Box>
                    );
                  }
                )}
              </Box>
            )}
            {/* End: Branches Colors Hint */}
            {branchesComparisonData?.question_text && (
              <HashLink
                title="Click to view more insights in the Reviews Page"
                to={`/reviews#${getNavigatableSectionSlug(
                  branchesComparisonData?.question_text
                )}`}
                // style={{ color: COLORS.black }}
                smooth
              >
                <IconButton size="large" sx={{ transform: "rotate(180deg)" }}>
                  <MenuOpen />
                </IconButton>
              </HashLink>
            )}
          </Box>
        </Box>

        {isFetching ? (
          <SkeletonForQuestionsBarChart />
        ) : (
          renderBranchesChart(
            selectedFiltersState.insightType,
            branchesComparisonData
          )
        )}
      </Box>
    </RevampedLandingPageCard>
  );
};

const SkeletonForQuestionsBarChart = () => {
  return (
    <Skeleton
      variant="rectangular"
      width={"100%"}
      height={400}
      sx={{ borderRadius: 5 }}
    />
  );
};

export function getNPSBarColor(value) {
  return value > 0 ? "#1C5E9B" : primaryColors.error[500];
}
export function getASBarColor(value) {
  if (value <= 1) return averageSatisfactionColors["Bad"];
  else if (value <= 2) return averageSatisfactionColors["Needs Improvement"];
  else if (value <= 3) return averageSatisfactionColors["Average"];
  else if (value <= 4) return averageSatisfactionColors["Good"];
  else if (value <= 5) return averageSatisfactionColors["Excellent"];
}
export function getCSATBarColor(value) {
  return value >= 50 ? "#1C5E9B" : primaryColors.error[500];
}
function renderBranchesChart(selectedInsightIndex, branchesComparisonData) {
  const testingData = [
    ...Array(32).fill(branchesComparisonData?.branches_data).flat(),
  ];

  switch (selectedInsightIndex) {
    case INSIGHTS_TYPES.nps: {
      return (
        <PositiveNegativeBarChart
          rotateXAxisTick={true}
          //   data={(branchesComparisonData?.branches_data ? testingData : []).map(
          data={(branchesComparisonData?.branches_data
            ? branchesComparisonData?.branches_data
            : []
          )?.map(function sliceLongNames(branchData) {
            const isTextLong = branchData.name.length > 15;
            const getTrimmedName = (name) => {
              return name.length > 12 ? `${name.slice(0, 12)}..` : name;
            };
            return {
              ...branchData,
              name: isTextLong
                ? getTrimmedName(branchData.name)
                : branchData.name,
              fullName: branchData.name,
            };
          })}
          YAxisScale={insightsOptions[selectedInsightIndex].scale}
          getBarColor={getNPSBarColor}
        />
      );
    }
    case INSIGHTS_TYPES.satisfactionAverage:
    case INSIGHTS_TYPES.csat: {
      return (
        <RevampedVerticalBarChart
          rotateXAxisTick={true}
          data={branchesComparisonData?.branches_data?.map(
            function sliceLongNames(branchData) {
              const isTextLong = branchData.name.length > 15;
              const getTrimmedName = (name) => {
                return name.length > 12 ? `${name.slice(0, 12)}..` : name;
              };
              return {
                ...branchData,
                name: isTextLong
                  ? getTrimmedName(branchData.name)
                  : branchData.name,
                fullName: branchData.name,
              };
            }
          )}
          valueType={
            selectedInsightIndex === INSIGHTS_TYPES.satisfactionAverage
              ? "plain_number"
              : "percentage"
          }
          YAxisScale={insightsOptions[selectedInsightIndex].scale}
          getBarColor={
            selectedInsightIndex === INSIGHTS_TYPES.satisfactionAverage
              ? getASBarColor
              : getCSATBarColor
          }
        />
      );
    }
    default:
      break;
  }
}
const INSIGHTS_TYPES = {
  nps: 0,
  satisfactionAverage: 1,
  csat: 2,
};

const insightsOptions = [
  {
    value: 0,
    label: "NPS (Net Promoters Score)",
    type: "nps",
    scale: [-100, -50, 0, 50, 100],
  },
  {
    value: 1,
    label: "Satisfaction Average",
    type: "satisfaction-average",
    scale: [0, 1, 2, 3, 4, 5],
  },
  {
    value: 2,
    label: "CSAT (Satisfaction Score)",
    type: "csat",
    scale: [0, 20, 40, 60, 80, 100],
  },
];
const compareOptions = [
  { value: 0, label: "Compare Branches" },
  { value: 1, label: "Compare Divisions" },
];

export const averageSatisfactionColors = {
  Excellent: "#3B7C0F",
  Good: "#66C61C",
  Average: "#FAC515",
  "Needs Improvement": "#FF692E",
  Bad: "#FF4405",
};

import { createContext, useCallback, useEffect, useReducer } from "react";

export const NotificationContext = createContext();

export const NotificationContextProvider = ({ children }) => {
  const [notificationState, dispatchNotificationState] = useReducer(
    notificationStateReducer,
    notificationsContextDefaultValues
  );

  const fireNotification = useCallback(
    ({
      title,
      description,
      type,
      verticalPosition,
      horizontalPosition,
      target,
      extraDetailsToRender,
      onCloseNotificationCallback,
      stickyTillClosed,
      width,
      closeAfter,
    }) => {
      dispatchNotificationState({
        type: NOTIFICATIONS_ACTIONS.setData,
        payload: {
          isOpen: true,
          title,
          description,
          type,
          verticalPosition,
          horizontalPosition,
          target,
          onCloseNotificationCallback,
          extraDetailsToRender,
          width,
          closeAfter,
          stickyTillClosed,
        },
      });
    },
    []
  );

  const closeNotification = useCallback(
    (closingEvent, closingReason) => {
      if (closingReason !== "clickaway") {
        dispatchNotificationState({
          type: NOTIFICATIONS_ACTIONS.closeNotification,
        });

        notificationState.onCloseNotificationCallback &&
          notificationState.onCloseNotificationCallback();
      }
    },
    [notificationState]
  );

  useEffect(
    function closeNotificationAutomatically() {
      if (notificationState.isOpen && !notificationState.stickyTillClosed) {
        setTimeout(
          function closeNotificationAfterMilliseconds() {
            closeNotification();
          },
          [notificationState.closeAfter ? notificationState.closeAfter : 10000]
        );
      }
    },
    [
      closeNotification,
      notificationState.closeAfter,
      notificationState.isOpen,
      notificationState.stickyTillClosed,
    ]
  );

  return (
    <NotificationContext.Provider
      value={{
        notificationState,
        fireNotification,
        closeNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

function notificationStateReducer(state, action) {
  switch (action.type) {
    case NOTIFICATIONS_ACTIONS.setData: {
      return {
        ...action.payload,
      };
    }
    case NOTIFICATIONS_ACTIONS.closeNotification: {
      return {
        ...state,
        isOpen: false,
      };
    }
    default: {
      return state;
    }
  }
}

const NOTIFICATIONS_ACTIONS = {
  setData: "SET-DATA",
  closeNotification: "CLOSE-NOTIFICATION",
};

export const NOTIFICATIONS_TYPE = {
  info: "INFO",
  error: "ERROR",
  success: "SUCCESS",
  message: "MESSAGE",
};

export const NOTIFICATIONS_VERTICAL_POSITION = {
  top: "top",
  bottom: "bottom",
};

export const NOTIFICATIONS_HORIZONTAL_POSITION = {
  left: "left",
  right: "right",
  center: "center",
};

export const NOTIFICATIONS_TARGET = {
  admin: "ADMIN",
  everyone: "EVERYONE",
};

export const NOTIFICATION_WIDTH = {
  small: "25%",
  medium: "50%",
  fullSize: "90%",
};
export const notificationsContextDefaultValues = {
  title: "",
  description: "",
  type: NOTIFICATIONS_TYPE.info,
  verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
  horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.center,
  target: NOTIFICATIONS_TARGET.admin,
  isOpen: false,
  onCloseNotificationCallback: undefined,
  extraDetailsToRender: null,
  width: NOTIFICATION_WIDTH.fullSize,
  closeAfter: undefined,
  stickyTillClosed: false,
};

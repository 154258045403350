import { OverallViewTable } from "../../../../revamped-overall-view/components/shared/OverallViewTable";
import { useListAPIsStateManagement } from "../../../../../reusable/hooks/useListAPIStateManagement";
import { useGetExistingMembers, useGetUserRoles } from "../../../api/members";
import { EmptyTableView } from "../../../reusable/EmptyTableView";
import { TableContainer } from "../../../../revamped-overall-view/components/shared/TableContainer";
import { Box } from "@mui/material";

import {
  defaultInvitedMembersListParams,
  useGetInvitedMembers,
} from "../../../api/members/getInvitedMembers";
import { InvitedMembersTableRowDetails } from "./InvitedMembersTableRowDetails";
import { useState } from "react";
import { DeleteInvitedMember } from "./DeleteInvitedMember";
import { InvitationDialogButton } from "../InvitationDialogButton";

export const InvitedMembersTable = () => {
  const [rowPanelDetailsType, setRowPanelDetailsType] = useState(
    rowPanelDetailsTypes.default
  );

  const {
    apiListArgsState,
    debouncedSearchText,
    updatePageHandler,
    searchQueryHandler,
  } = useListAPIsStateManagement({
    initialState: {
      ...defaultInvitedMembersListParams,
    },
  });

  const {
    data: existingMembersResponse,
    isFetching: isFetchingInvitedMembers,
    isError: invitedMembersFetchingError,
    isLoading: isLoadingExistingMembers,
  } = useGetExistingMembers({
    params: { page: apiListArgsState.page, searchValue: debouncedSearchText },
  });

  const {
    data: invitedMembersResponse,
    isFetching: isFetchingMembers,
    isError: membersFetchingError,
    isLoading: isLoadingInvitedMembers,
  } = useGetInvitedMembers({
    params: { page: apiListArgsState.page, searchValue: debouncedSearchText },
  });

  const { data: userRolesResponse, isLoading: isLoadingUserRoles } =
    useGetUserRoles();

  function renderDynamicRowPanelDetails({ rowPanelDetailsTypeArg, row }) {
    switch (rowPanelDetailsTypeArg) {
      case rowPanelDetailsTypes.default:
        return <InvitedMembersTableRowDetails memberDetails={row.original} />;
      //   case rowPanelDetailsTypes.edit:
      //     return <InvitedMembersTableEditDetails memberDetails={row.original} />;
      default: {
        return null;
      }
    }
  }

  const membersTableColumns = [
    {
      header: "Email",
      id: "email",
      accessorKey: "email",
      accessorFn: function renderUserEmail(invitedMemberData) {
        console.log(invitedMemberData, "?????????");
        if (invitedMemberData.email.length > 0) return invitedMemberData.email;
        else return "N/A";
      },
      minSize: 50,
      size: 300,
    },
    {
      header: "Role",
      id: "role",
      accessorFn: function renderUserRole(invitedMemberData) {
        for (let userRole of userRolesResponse.data) {
          if (userRole.value === invitedMemberData.role) return userRole.label;
        }
      },
      minSize: 50,
      size: 300,
    },
    {
      header: "",
      id: "actions",
      muiTableHeadCellProps: {
        align: "right",
      },
      muiTableBodyCellProps: {
        align: "right",
      },
      accessorFn: function renderMemberActions(invitedMemberData) {
        return (
          <Box>
            <DeleteInvitedMember invitedMemberData={invitedMemberData} />
          </Box>
        );
      },
      minSize: 50,
      size: 350,
    },
  ];

  const allMembersTableView = (
    <OverallViewTable
      columns={membersTableColumns}
      data={invitedMembersResponse?.data.results || []}
      totalRecords={invitedMembersResponse?.data.count}
      isLoading={isLoadingInvitedMembers || isLoadingUserRoles}
      isError={membersFetchingError}
      externalPaginationProps={{
        externalCurrentPage: apiListArgsState.page,
        updateExternalCurrentPage: updatePageHandler,
        isFetching: isFetchingMembers,
      }}
      uniqueColumnName="email"
      //   resetRowPanelDetailsTypeCallback={function setRowPanelDetailsTypeToDefaultOnClose() {
      //     setRowPanelDetailsType(rowPanelDetailsTypes.default);
      //   }}
      renderRowDetails={({ row }) => {
        return renderDynamicRowPanelDetails({
          rowPanelDetailsTypeArg: rowPanelDetailsType,
          row,
        });
      }}
      searchProps={{
        enabled: true,
        placeholder: "Search by Email",
        onSearchCallback: searchQueryHandler,
      }}
    />
  );

  const noMembersView = invitedMembersResponse && existingMembersResponse && (
    <EmptyTableView
      title="No new members have been invited"
      description="Get your team on board and let the journey begin! There are various
      roles available, so you can assign each member to a specific branch
      and division."
      actionButton={
        <InvitationDialogButton
          disabled={
            existingMembersResponse.data.count >=
            existingMembersResponse.data.users_max_count
          }
        />
      }
    />
  );

  let membersTableView = null;

  if (
    isLoadingInvitedMembers ||
    isLoadingUserRoles ||
    invitedMembersResponse?.data.results.length > 0
  ) {
    membersTableView = allMembersTableView;
  }

  if (
    !isLoadingInvitedMembers &&
    !isLoadingUserRoles &&
    invitedMembersResponse?.data.results.length === 0
  ) {
    membersTableView = noMembersView;
  }

  return (
    <TableContainer
      label={"Invited Team Members"}
      tableActionsNextToName={
        <Box display="flex" flexDirection={"row"} alignItems="center">
          {existingMembersResponse && (
            <InvitationDialogButton
              disabled={
                existingMembersResponse.data.count >=
                existingMembersResponse.data.users_max_count
              }
            />
          )}
        </Box>
      }
    >
      {membersTableView}
    </TableContainer>
  );
};

const rowPanelDetailsTypes = {
  default: "default",
  edit: "edit",
};
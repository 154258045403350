import { primaryColors } from "./customColors";

export const scrollbarStyles = {
  "&::-webkit-scrollbar": {
    width: "8px",
    height: "10px",
  },
  "&::-webkit-scrollbar-track": {
    background: "white",
  },
  "&::-webkit-scrollbar-thumb": {
    background: primaryColors.gray[300],
    borderRadius: "6px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: primaryColors.gray[400],
  },
};

import { API_URL, melior } from "../../../helpers/constants";
import { performanceRankingOptions } from "../components/AllBranchesComparisonTable";

export const getAllBranchesInsights = async ({
  startDate,
  endDate,
  governorates,
  facilityTypes,
  branches,
  divisions,
  selectedRankingOption,
}) => {
  const requestURL = `${API_URL}/branches-insights`;
  return melior
    .get(requestURL, {
      params: {
        date_from: startDate,
        date_to: endDate,
        ...(governorates?.length > 0
          ? { governorates: governorates?.join(",") }
          : {}),
        ...(facilityTypes?.length > 0
          ? { facility_types: facilityTypes?.join(",") }
          : {}),
        ...(branches?.length > 0 ? { branches: branches?.join(",") } : {}),
        ...(divisions?.length > 0 ? { divisions: divisions?.join(",") } : {}),
        rank_divisions:
          selectedRankingOption === performanceRankingOptions[1].value
            ? true
            : false,
      },
    })
    .then(async function returnResponse(response) {
      return response;
    });
};

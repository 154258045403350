import { Typography, Box, Skeleton } from "@mui/material";
import { RevampedHorizontalBarChart } from "../../../../reusable/components/charts/RevampedHorizontalBarChart";
import { RevampedLandingPageCard } from "../../../revamped-landing-page/components/RevampedLandingPage";
import { fontWeightVariations } from "../../../../helpers/customFont";
import { primaryColors } from "../../../../helpers/customColors";
import { DynamicFilters } from "../shared/DynamicFilters";
import { useGetComplaintsRecurrence } from "../../api/complaints-overview";
import { IsLoadingContainer } from "../../../../reusable/components/IsLoadingContainer";
import {
  useDynamicFiltersState,
  getLowestLevelSelectedFilter,
} from "../../hooks/useDynamicFiltersState";
import { useEffect } from "react";

export const ComplaintsRecurrenceBarChart = ({
  startDate,
  endDate,
  governorates,
  facilityTypes,
  branches,
  divisions,
  relatedTeams,
  complaintsClassificationDropdowns,
}) => {
  const { selectedFiltersState, updateSelectedFilters, replaceFilterState } =
    useDynamicFiltersState({
      isEachFilterIndependent: false,
      serverFiltersData: complaintsClassificationDropdowns,
    });

  const {
    data: complaintsRecurrenceServerResponse,
    isFetching: isFetchingComplaintsRecurrence,
  } = useGetComplaintsRecurrence({
    params: {
      startDate,
      endDate,
      governorates,
      facilityTypes,
      branches,
      divisions,
      relatedTeams,
      labelsIDs: getLowestLevelSelectedFilter({
        selectedFiltersState,
        filtersData: complaintsClassificationDropdowns,
      }),
    },
    config: { staleTime: 120000 },
  });

  useEffect(
    function updateFilterData() {
      if (complaintsClassificationDropdowns) {
        replaceFilterState({
          newFiltersData: complaintsClassificationDropdowns,
        });
      }
    },
    [complaintsClassificationDropdowns, replaceFilterState]
  );

  return (
    <RevampedLandingPageCard
      showSeparator={true}
      labelHeight="30px"
      label={
        <Typography
          variant="text-lg"
          fontWeight={fontWeightVariations.medium}
          color={primaryColors.gray[900]}
        >
          Complaints Recurrence
        </Typography>
      }
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <IsLoadingContainer
          isLoading={!complaintsClassificationDropdowns}
          size={10}
        >
          <DynamicFilters
            selectedFiltersState={selectedFiltersState}
            updateSelectedFilters={updateSelectedFilters}
            addShowAllOption={true}
          />
        </IsLoadingContainer>
        {isFetchingComplaintsRecurrence ? (
          <SkeletonForComplaintsBarChart />
        ) : (
          <RevampedHorizontalBarChart
            data={complaintsRecurrenceServerResponse?.data.map(
              function prepareChartData(complaint) {
                return {
                  name: complaint.text,
                  value: complaint.recurrence,
                  tooltip: `Highest recurrence at ${complaint.highest.name}`,
                };
              }
            )}
          />
        )}
      </Box>
    </RevampedLandingPageCard>
  );
};
const SkeletonForComplaintsBarChart = () => {
  return (
    <Skeleton
      variant="rectangular"
      width={"100%"}
      height={400}
      sx={{ borderRadius: 5 }}
    />
  );
};

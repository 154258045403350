import { useContext, useMemo } from "react";
import { AuthenticationContext } from "../features/login";
import { FEEDBACK_ONLY, FACILITY_MANAGER, DEMO } from "../helpers/constants";
import {
  InsertChart,
  PermPhoneMsg,
  Build,
  Settings,
  Send,
  BusinessCenter,
} from "@mui/icons-material";

export const useNavItems = () => {
  const { user } = useContext(AuthenticationContext);

  const mainNavItems = useMemo(
    function prepareMainNavItemsList() {
      return [
        {
          title: "Analytics",
          icon: <InsertChart />,
          to: "/",
          permitted: user?.role !== FEEDBACK_ONLY,
        },
        {
          title: "Management",
          icon: <BusinessCenter />,
          to: "/overall-view",
          permitted: true,
        },
        // {
        //   title: "Distribution",
        //   icon: <Send />,
        //   to: "/link-generator",
        //   permitted: user?.role !== FEEDBACK_ONLY,
        // },
        {
          title: "Distribution",
          icon: <Send />,
          to: "/distribution",
          permitted: user?.role !== FEEDBACK_ONLY,
        },
        {
          title: "Input",
          icon: <PermPhoneMsg />,
          to: "/outbound",
          permitted: true,
        },
        {
          title: "Facility Management",
          icon: <Build />,
          to: "/facility-management",
          permitted: user?.role === FACILITY_MANAGER || user?.role === DEMO,
        },
        {
          title: "Settings",
          icon: <Settings />,
          to: "/settings",
          permitted: user?.role !== FEEDBACK_ONLY,
        },
      ];
    },
    [user?.role]
  );

  const secondaryNavItems = useMemo(function prepareSecondaryNavItemsList() {
    return [];
  }, []);

  return { mainNavItems, secondaryNavItems };
};

import { OverallProgress } from "../components/reviews-overview";

export const ReviewsOverviewSubScreen = ({ selectedFiltersState }) => {
  return (
    <div>
        <h1>ALL GOOD</h1>
        <OverallProgress
          filters={{
            startDate: selectedFiltersState.startDate,
            endDate: selectedFiltersState.endDate,
            divisions: selectedFiltersState.divisions,
            branches: selectedFiltersState.branches,
            //   channels: selectedFiltersState.channels,
          }}
        />
    </div>
  );
};

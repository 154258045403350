import { Box, Skeleton, Typography } from "@mui/material";
import { RevampedLandingPageCard } from "../../../revamped-landing-page/components/RevampedLandingPage";
import { fontWeightVariations } from "../../../../helpers/customFont";
import {
  primaryColors,
  secondaryColors,
} from "../../../../helpers/customColors";
import { RevampedVerticalBarChart } from "../../../../reusable/components/charts";
import { getCSATBarColor } from "../../../revamped-landing-page/components/AllBranchesQuestionBarChart";
import { hourMilliseconds } from "../../../../helpers/timeHelpers";
import { useGetComplaintsMetricsComparison } from "../../api/complaints-overview";
import { DynamicFilters } from "../shared/DynamicFilters";
import { useDynamicFiltersState } from "../../hooks/useDynamicFiltersState";
import { useCallback, useEffect } from "react";

export const ComplaintsMetricsComparisonBarChart = ({
  startDate,
  endDate,
  governorates,
  facilityTypes,
  branches,
  divisions,
  relatedTeams,
}) => {
  const getFiltersData = useCallback(function prepareFiltersData() {
    return [
      { dropdownName: "insightType", dropdownOptions: insightsOptions },
    //   {
    //     dropdownName: "compare",
    //     disableDropdown: true,
    //     dropdownOptions: compareOptions,
    //   },
    ];
  }, []);
  const { selectedFiltersState, updateSelectedFilters, replaceFilterState } =
    useDynamicFiltersState({
      isEachFilterIndependent: true,
      serverFiltersData: getFiltersData(),
      initialFiltersState: {
        insightType: 0,
        compare: 0,
      },
    });
  const {
    data: complaintsMetricsComparisonServerResponse,
    isFetching: isFetchingComplaintsMetricsComparison,
  } = useGetComplaintsMetricsComparison({
    params: {
      chartType: insightsOptions[selectedFiltersState?.insightType]?.type,
      startDate,
      endDate,
      governorates,
      facilityTypes,
      branches,
      divisions,
      relatedTeams,
      config: { staleTime: 120000 },
    },
  });

  //   useEffect(
  //     // to update disableDropdown condition
  //     function updateFiltersData() {
  //       replaceFilterState({newFiltersData: getFiltersData()});
  //     },
  //     [getFiltersData, replaceFilterState]
  //   );
  useEffect(
    function updateSelectedOptionForCompareDropdown() {
      let compareSelectedValue;
      const multipleBranchesSelected =
        branches.length !== 1 && divisions.length === 0;
      compareSelectedValue = multipleBranchesSelected
        ? compareOptions[0].value
        : compareOptions[1].value;
      updateSelectedFilters({
        target: { name: "compare", value: compareSelectedValue },
      });
    },
    [branches, divisions]
  );

  return (
    <RevampedLandingPageCard
      showSeparator={true}
      labelHeight="30px"
      label={
        <Typography
          variant="text-lg"
          fontWeight={fontWeightVariations.medium}
          color={primaryColors.gray[900]}
        >
          Complaints Metrics Comparison
        </Typography>
      }
    >
      <Box display="flex" flexDirection="column" height="400px" gap={2}>
        <DynamicFilters
          selectedFiltersState={selectedFiltersState}
          updateSelectedFilters={updateSelectedFilters}
        />
        {isFetchingComplaintsMetricsComparison ? (
          <SkeletonForComplaintsBarChart />
        ) : (
          renderComplaintsMetricsComparisonChart({
            selectedInsightIndex: selectedFiltersState?.insightType,
            complaintsComparisonData:
              complaintsMetricsComparisonServerResponse?.data?.map(
                function prepareChartData(data, index) {
                  return { ...data, id: index };
                }
              ),
          })
        )}
      </Box>
    </RevampedLandingPageCard>
  );
};

const SkeletonForComplaintsBarChart = () => {
  return (
    <Skeleton
      variant="rectangular"
      width={"100%"}
      height={400}
      sx={{ borderRadius: 5 }}
    />
  );
};

function getResolutionRateBarColor(value) {
  return value > 50 ? secondaryColors.green[500] : primaryColors.gray[300];
}
function getAverageResolutionTimeBarColor(value) {
  return value > 0 ? secondaryColors.green[500] : primaryColors.gray[300];
}
function renderComplaintsMetricsComparisonChart({
  selectedInsightIndex,
  complaintsComparisonData,
}) {
  switch (selectedInsightIndex) {
    case INSIGHTS_TYPES.resolutionRate: {
      return (
        <RevampedVerticalBarChart
          rotateXAxisTick={true}
          data={complaintsComparisonData.map(function sliceLongNames(
            branchData
          ) {
            const isTextLong = branchData.name.length > 15;
            const getTrimmedName = (name) => {
              return name.length > 12 ? `${name.slice(0, 12)}..` : name;
            };
            return {
              ...branchData,
              name: isTextLong
                ? getTrimmedName(branchData.name)
                : branchData.name,
              fullName: branchData.name,
            };
          })}
          valueType={"percentage"}
          YAxisScale={insightsOptions[selectedInsightIndex].scale}
          getBarColor={getResolutionRateBarColor}
        />
      );
    }
    case INSIGHTS_TYPES.averageResolutionTime: {
      return (
        <RevampedVerticalBarChart
          rotateXAxisTick={true}
          data={complaintsComparisonData.map(function formatBranchData(
            branchData
          ) {
            const isTextLong = branchData.name.length > 15;
            const getTrimmedName = (name) => {
              return name.length > 12 ? `${name.slice(0, 12)}..` : name;
            };
            return {
              ...branchData,
              name: isTextLong
                ? getTrimmedName(branchData.name)
                : branchData.name,
              fullName: branchData.name,
              value: (branchData.value / hourMilliseconds).toFixed(1),
            };
          })}
          labelPosition="outside"
          valueType={"time"}
          YAxisScale={insightsOptions[selectedInsightIndex].scale}
          getBarColor={getAverageResolutionTimeBarColor}
        />
      );
    }
    case INSIGHTS_TYPES.resolutionSatisfaction: {
      return (
        <RevampedVerticalBarChart
          rotateXAxisTick={true}
          data={complaintsComparisonData.map(function sliceLongNames(
            branchData
          ) {
            const isTextLong = branchData.name.length > 15;
            const getTrimmedName = (name) => {
              return name.length > 12 ? `${name.slice(0, 12)}..` : name;
            };
            return {
              ...branchData,
              name: isTextLong
                ? getTrimmedName(branchData.name)
                : branchData.name,
              fullName: branchData.name,
            };
          })}
          valueType={"percentage"}
          YAxisScale={insightsOptions[selectedInsightIndex]?.scale}
          getBarColor={getCSATBarColor}
        />
      );
    }
    default:
      break;
  }
}
const INSIGHTS_TYPES = {
  resolutionRate: 0,
  averageResolutionTime: 1,
  resolutionSatisfaction: 2,
};
const insightsOptions = [
  {
    value: 0,
    label: "Resolution Rate",
    type: "resolution-rate",
    scale: [0, 20, 40, 60, 80, 100],
  },
  {
    value: 1,
    label: "Average Resolution Time",
    type: "average-resolution-time",
  },
  {
    value: 2,
    label: "Resolution Satisfaction (CSAT)",
    type: "resolution-satisfaction",
    scale: [0, 20, 40, 60, 80, 100],
  },
];

const compareOptions = [
  { value: 0, label: "Compare Branches" },
  { value: 1, label: "Compare Divisions" },
];

import {
  Button,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { primaryColors } from "../../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import { useState, useEffect, useContext } from "react";
import {
  GenericDialog,
  SUBMIT_BUTTON_TYPES,
} from "../../../../reusable/components/GenericDialog";
import {
  FormDropdown,
  FormMultiSelect,
  FormTextInput,
} from "../../../../reusable/components/form-inputs/controlled";
import { useReactHookFormWithYup } from "../../../../reusable/hooks";
import { FormProvider } from "react-hook-form";
import * as yup from "yup";
import {
  useGetAllBranches,
  useGetUserRoles,
  useInviteMember,
} from "../../api/members";

export const InvitationDialogButton = () => {
  const [allBranchesData, setAllBranchesData] = useState([]);

  const validationSchema = yup.object({
    email: yup
      .string()
      .required("enter email address")
      .email("email is not valid"),
    divisions: yup
      .array()
      .min(1, "select at least one division")
      .required("select at least one division"),
    role: yup.string().required("select a role"),
  });
  const reactHookFormMethods = useReactHookFormWithYup({
    validationSchema,
    defaultValues: {
      divisions: [],
    },
  });
  const { reset, handleSubmit, watch, formState } = reactHookFormMethods;

  const inviteMemberMutation = useInviteMember();

  const invitationHandler = async (invitationData) => {
    inviteMemberMutation.mutateAsync(invitationData);
  };

  const { data: allBranchesResponse, isLoading: isLoadingAllBranches } =
    useGetAllBranches();
  const { data: userRolesResponse, isLoading: isLoadingUserRoles } =
    useGetUserRoles();

  useEffect(() => {
    if (allBranchesResponse && !isLoadingAllBranches) {
      let tempAllBranchesData = [];
      allBranchesResponse.data.items.forEach(function getBranchesAsOptions(
        branchData
      ) {
        tempAllBranchesData.push({
          value: branchData.id,
          isBranch: true,
          label: branchData.name,
        });

        if (branchData.divisions.length > 0) {
          branchData.divisions.forEach(function getDivisionsAsFilterOptions(
            divisionData
          ) {
            tempAllBranchesData.push({
              value: divisionData.id,
              label: divisionData.name,
            });
          });
        }
      });
      setAllBranchesData([...tempAllBranchesData]);
    }
  }, [allBranchesResponse, isLoadingAllBranches]);

  return (
    <GenericDialog
      dialogTitle="Invite members"
      dialogSubtitle={
        "Your account has been created. Invite members to collaborate on this account."
      }
      maxWidth="xs"
      triggerButton={
        <Button
          sx={{
            borderRadius: "8px",
            padding: "10px 16px",
            backgroundColor: primaryColors.brand[500],
            "&:hover": {
              backgroundColor: primaryColors.brand[500],
            },
          }}
        >
          <AddIcon sx={{ color: primaryColors.base.white }} fontSize="small" />
          <Typography
            sx={{
              textTransform: "none",
              fontSize: fontSizeVariations["text-sm"],
              fontWeight: fontWeightVariations["semiBold-600"],
              color: primaryColors.base.white,
            }}
          >
            Add new member
          </Typography>
        </Button>
      }
      triggerButtonDisplay="inline"
      submitButtonType={SUBMIT_BUTTON_TYPES.submit}
      disableSubmitCondition={!formState.isDirty}
      submitButtonText={"Send invitation"}
      onSubmitCallback={handleSubmit((invitationData) => {
        invitationHandler(invitationData);
      })}
      onCloseCallback={reset}
      closeButtonText={"Cancel"}
    >
      <FormProvider {...reactHookFormMethods}>
        <form method="POST">
          <Grid container gap="20px">
            {/* branch dropdown */}
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: primaryColors.gray[700],
                  fontSize: fontSizeVariations["text-sm"],
                  fontWeight: fontWeightVariations["bold-700"],
                  marginBottom: "6px",
                }}
              >
                Branch
              </Typography>
              <FormMultiSelect
                name="divisions"
                label="Select branches & divisions"
                sx={{
                  borderRadius: "8px",
                  color: primaryColors.gray[700],
                }}
              >
                {allBranchesData.map(function renderDivisionsOptionsGroup(
                  branchDivOption,
                  index
                ) {
                  return (
                    <MenuItem
                      aria-invalid={branchDivOption.isBranch}
                      disabled={branchDivOption.isBranch}
                      key={index}
                      value={
                        branchDivOption.isBranch ? "" : branchDivOption.value
                      }
                      sx={{
                        pointerEvents: branchDivOption.isBranch
                          ? "none"
                          : undefined,
                        paddingLeft: branchDivOption.isBranch ? undefined : 2,
                      }}
                    >
                      {!branchDivOption.isBranch && (
                        <Checkbox
                          size="small"
                          checked={watch("divisions")?.includes(
                            branchDivOption.value
                          )}
                        />
                      )}
                      <ListItemText
                        primary={branchDivOption.label}
                        sx={{
                          fontSize: "14px",
                          fontWeight: branchDivOption.isBranch ? "bold" : "",
                        }}
                      />
                    </MenuItem>
                  );
                })}
              </FormMultiSelect>
            </Grid>
            {/* email address input */}
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: primaryColors.gray[700],
                  fontSize: fontSizeVariations["text-sm"],
                  fontWeight: fontWeightVariations["bold-700"],
                  marginBottom: "6px",
                }}
              >
                Email address
              </Typography>
              <FormTextInput
                name="email"
                placeholder="you@facility.com"
                type="email"
                sx={{
                  color: primaryColors.gray[500],
                  backgroundColor: primaryColors.base.white,
                  border: `1px solid ${primaryColors.gray[300]}`,
                  borderRadius: "8px",
                }}
              />
            </Grid>
            {/* roles dropdown */}
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: primaryColors.gray[700],
                  fontSize: fontSizeVariations["text-sm"],
                  fontWeight: fontWeightVariations["bold-700"],
                  marginBottom: "6px",
                }}
              >
                Roles
              </Typography>
              <FormDropdown
                name="role"
                label="Select a role"
                options={!isLoadingUserRoles ? userRolesResponse.data : []}
                sx={{
                  borderRadius: "8px",
                  color: primaryColors.gray[700],
                }}
              ></FormDropdown>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </GenericDialog>
  );
};

import { Box, Button } from "@mui/material";
import { Filtration } from "../shared/Filtration";
import { useOverallViewFiltersState } from "../shared/useOverallViewFiltersState";
import { OverallViewTable } from "../shared/OverallViewTable";
import { useGetComplaints } from "../../api/complaints/getComplaints";
import { useSearchParams } from "react-router-dom";
import { searchTypes } from "../shared/DynamicSearchInput";
import { ComplaintTableRowDetails } from "./ComplaintTableRowDetails";
import { Link } from "react-router-dom";
import { TableContainer } from "../shared/TableContainer";
import { ComplaintTable, useComplaintsTableColumns } from "./ComplaintsTable";

export const ComplaintsTablePage = ({ starred, archived }) => {
  const {
    selectedFiltersState,
    updateSelectedGovernorates,
    removeSpecificGovernorate,
    updateSelectedFacilityTypes,
    removeSpecificFacilityType,
    updateSelectedDivisions,
    updateSelectedDateRange,
    resetDateRange,
    updateSelectedChannels,
    removeSpecificChannel,
    removeSpecificDivision,
    // updateWithCommentFilterState,
    updateComplaintStatusFilterState,
    updateComplaintsAssignees,
    removeSpecificComplaintAssignee,
    updateArchivedFilterState,
    updateBookmarkedFilterState,
    updateSelectedRelatedTeams,
  } = useOverallViewFiltersState();

  // Start: Related to Single Review View
  let [searchParams] = useSearchParams();
  const { complaintsTableColumns } = useComplaintsTableColumns();

  const complaintID = searchParams.get("complaintID");

  const complaintQueryParams = {
    page: 1,
  };

  complaintQueryParams[searchTypes.id.key] = complaintID;

  const {
    data: singleComplaintSearchResultResponse,
    isFetching: isFetchingSingleComplaint,
    isError: singleComplaintFetchingError,
    isLoading: isLoadingComplaints,
  } = useGetComplaints({
    params: { ...complaintQueryParams },
    config: { enabled: complaintID !== null },
  });

  const singleComplaintView =
    singleComplaintSearchResultResponse === undefined ? null : (
      <TableContainer label={`Complaint ID #${complaintID}`}>
        <OverallViewTable
          columns={complaintsTableColumns}
          data={singleComplaintSearchResultResponse.data.results}
          totalRecords={singleComplaintSearchResultResponse?.data.total_count}
          isLoading={isLoadingComplaints}
          isError={singleComplaintFetchingError}
          externalPaginationProps={{
            externalCurrentPage: 1,
            updateExternalCurrentPage: undefined,
            isFetching: isFetchingSingleComplaint,
          }}
          renderRowDetails={({ row }) => {
            return (
              <ComplaintTableRowDetails
                isFetchingComplaintsList={isFetchingSingleComplaint}
                complaintDetails={row.original}
                isExpanded={true}
              />
            );
          }}
          expandAllByDefault
        />
      </TableContainer>
    );
  // Start: Related to Single Review View

  const complaintsTableView = (
    <ComplaintTable
      selectedFiltersState={selectedFiltersState}
      starred={starred}
      archived={archived}
    />
  );

  const showSingleComplaintView =
    singleComplaintSearchResultResponse && complaintID !== null;
  
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        {/* Hide filtration in case we're in a single Complaint View */}
        {!complaintID && (
          <Filtration
            selectedFiltersState={selectedFiltersState}
            dateRangeFilter={{
              selectedDateRange: {
                date_from: selectedFiltersState.date_from,
                date_to: selectedFiltersState.date_to,
              },
              onChange: updateSelectedDateRange,
              resetDateRange,
            }}
            governoratesFilter={{
              selectedGovernorates: selectedFiltersState.governorates,
              onChange: updateSelectedGovernorates,
              removeSpecificGovernorate: removeSpecificGovernorate,
            }}
            facilityTypesFilter={{
              selectedFacilityTypes: selectedFiltersState.facilityTypes,
              onChange: updateSelectedFacilityTypes,
              removeSpecificFacilityType: removeSpecificFacilityType,
            }}
            branchesAndDivisionsFilter={{
              selectedDivisions: selectedFiltersState.divisions,
              onChange: updateSelectedDivisions,
              removeSpecificDivision,
            }}
            channelsFilter={{
              selectedChannels: selectedFiltersState.channels,
              onChange: updateSelectedChannels,
              removeSpecificChannel,
            }}
            //   withCommentFilter={{
            //     withComment: selectedFiltersState.withComment,
            //     onChange: updateWithCommentFilterState,
            //   }}
            complaintStatusFilter={{
              status: selectedFiltersState.complaintStatus,
              onChange: updateComplaintStatusFilterState,
            }}
            complaintAssigneesFilter={{
              selectedAssignees: selectedFiltersState.complaintsAssignees,
              onChange: updateComplaintsAssignees,
              removeSpecificAssignee: removeSpecificComplaintAssignee,
            }}
            archivedFilter={{
              archived: selectedFiltersState.archived,
              onChange: updateArchivedFilterState,
            }}
            bookmarkedFilter={{
              bookmarked: selectedFiltersState.bookmarked,
              onChange: updateBookmarkedFilterState,
            }}
            relatedTeamsFilter={{
              selectedRelatedTeams: selectedFiltersState.selectedRelatedTeams,
              onChange: updateSelectedRelatedTeams,
            }}
          />
        )}

        {complaintID && (
          <Link to={"/overall-view/complaints"}>
            <Button variant="outlined" sx={{ textTransform: "capitalize" }}>
              Show All Complaints
            </Button>
          </Link>
        )}
      </Box>

      {showSingleComplaintView ? singleComplaintView : complaintsTableView}
    </Box>
  );
};

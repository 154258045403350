import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

const getAllFacilityTypes = () => {
  const requestURL = `${API_URL}/tags/facility-types`;

  return melior.get(requestURL);
};

export const useGetAllFacilityTypes = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["all-facility-types"],
    queryFn: () => getAllFacilityTypes(),
    keepPreviousData: true,
  });
};
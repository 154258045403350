import { Grid, Typography } from "@mui/material";
import { primaryColors } from "../../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import { useEffect, useState, useContext } from "react";
import { alterCurrentUserDetails, getCurrentUserDetails } from "../../api";
import { FormProvider } from "react-hook-form";
import * as yup from "yup";
import { FormTextInput } from "../../../../reusable/components/form-inputs/controlled";
import { useReactHookFormWithYup } from "../../../../reusable/hooks";

import {
  NotificationContext,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATIONS_TARGET,
} from "../../../../contexts/NotificationContext";
import { FormContainer } from "./FormContainer";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { PasswordConfirmationDialog } from "../../../settings";
export const MemberInformationSection = () => {
  const { fireNotification } = useContext(NotificationContext);
  const [userData, setUserData] = useState({
    username: "",
    first_name: "",
    last_name: "",
    email: "",
  });
  const [submitting, setSubmitting] = useState(false);
  const [isUsernameUnique, setIsUsernameUnique] = useState(true);
  const [confirmationModal, setConfirmationModal] = useState({
    openDialog: false,
    title: "",
    description: "",
    callbackFunction: () => {},
  });

  const validationSchema = yup.object({
    email: yup.string().email("email is not valid"),
    first_name: yup
      .string()
      .matches(/^[a-zA-Z]+$/, "name can only contain letters"),
    last_name: yup
      .string()
      .matches(/^[a-zA-Z]+$/, "name can only contain letters"),
  });
  const reactHookFormMethods = useReactHookFormWithYup({
    validationSchema,
    defaultValues: {
      username: userData.username,
      first_name: userData.first_name,
      last_name: userData.last_name,
      email: userData.email,
    },
  });
  const { reset, handleSubmit, getValues, formState } = reactHookFormMethods;

  const updateProfileInfoHandler = (currentPassword) => {
    setConfirmationModal({
      openDialog: false,
    });
    setSubmitting(true);
    let memberData = {
      first_name: getValues("first_name"),
      last_name: getValues("last_name"),
    };
    if (formState.dirtyFields.username) {
      //user name changed
      memberData.username = getValues("username");
    }
    if (formState.dirtyFields.email) {
      //email changed
      memberData.email = getValues("email");
      memberData.password = currentPassword;
    }

    alterCurrentUserDetails(memberData)
      .then((response) => {
        setUserData(response);
        fireNotification({
          title: "Changed successfully",
          type: NOTIFICATIONS_TYPE.success,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
          target: NOTIFICATIONS_TARGET.everyone,
        });
        setSubmitting(false);
      })
      .catch((error) => {
        if (formState.dirtyFields.username) setIsUsernameUnique(false);
        // view error msg notification
        fireNotification({
          title: "An error occurred",
          description: "please, try agarin later",
          type: NOTIFICATIONS_TYPE.error,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
          target: NOTIFICATIONS_TARGET.everyone,
        });
        setSubmitting(false);
      });
  };

  const resetHandler = () => {
    setIsUsernameUnique(true);
    reset(userData);
  };

  useEffect(() => {
    getCurrentUserDetails()
      .then((response) => {
        setUserData(response);
        reset(response);
      })
      .catch((error) => console.log(error));
  }, [reset]);

  return (
    <FormProvider {...reactHookFormMethods}>
      <form
        method="PATCH"
        onSubmit={handleSubmit(() => {
          //when email field is dirty

          if (formState.dirtyFields.email) {
            //case 1: need confirmation
            setConfirmationModal({ openDialog: false });
            setConfirmationModal({
              openDialog: true,
              title: "Confirmation",
              description: "Please enter current password to change email",
              callbackFunction: updateProfileInfoHandler,
            });
          } else {
            //case 2: don't need confirmation
            updateProfileInfoHandler(null);
          }
        })}
      >
        <FormContainer
          formTitle={"Member Information"}
          formState={formState}
          submitting={submitting}
          resetCallback={resetHandler}
        >
          <Grid
            container
            justifyContent="flex-start"
            direction="row"
            width={{ sm: "100%", lg: "660px", md: "660px", xl: "660px" }}
            mr={"50%"}
            spacing={2}
          >
            {/* form fields */}
            {MemberInformationTextField(
              "First Name",
              "first_name",
              "Enter your first name"
            )}
            {MemberInformationTextField(
              "Last Name",
              "last_name",
              "Enter your last name"
            )}
            {MemberInformationTextField(
              "Email",
              "email",
              "Enter your facility email"
            )}
            {MemberInformationUsernameField(isUsernameUnique)}
          </Grid>
        </FormContainer>
      </form>
      {/* confirm changes by entering old password */}
      <PasswordConfirmationDialog {...confirmationModal}></PasswordConfirmationDialog>
    </FormProvider>
  );
};

const MemberInformationTextField = (label, name, placeholder, children) => {
  return (
    <Grid item xs={12} md={6}>
      <Typography
        sx={{
          fontFamily: "Nunito",
          fontSize: fontSizeVariations["text-sm"],
          fontWeight: fontWeightVariations["medium-500"],
          color: primaryColors.gray[700],
          marginBottom: "6px",
        }}
      >
        {label}
      </Typography>
      <FormTextInput
        name={name}
        placeholder={placeholder}
        type="text"
        sx={{
          color: primaryColors.gray[500],
          backgroundColor: primaryColors.base.white,
          border: `1px solid ${primaryColors.gray[300]}`,
          borderRadius: "8px",
        }}
      />
      {children}
    </Grid>
  );
};

const MemberInformationUsernameField = (isUsernameUnique) => {
  return (
    <Grid item xs={12} md={6}>
      <Typography
        sx={{
          fontFamily: "Nunito",
          fontSize: fontSizeVariations["text-sm"],
          fontWeight: fontWeightVariations["medium-500"],
          color: primaryColors.gray[700],
          marginBottom: "6px",
        }}
      >
        Username
      </Typography>
      <FormTextInput
        name="username"
        //   value={userData.username}
        error={!isUsernameUnique}
        placeholder="Enter a username"
        type="text"
        sx={{
          color: primaryColors.gray[500],
          backgroundColor: primaryColors.base.white,
          border: `1px solid ${primaryColors.gray[300]}`,
          borderRadius: "8px",
        }}
      />
      {/* username helper text */}
      {!isUsernameUnique && (
        <Typography
          sx={{
            color: primaryColors.error[600],
            fontWeight: fontWeightVariations["medium-500"],
            fontSize: fontSizeVariations["text-sm"],
            marginTop: "6px",
          }}
        >
          This Username is not unique
        </Typography>
      )}
    </Grid>
  );
};

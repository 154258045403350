import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { primaryColors } from "../../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import { useState } from "react";
import { giveFeedback } from "../../api";
import {
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NotificationContext,
} from "../../../../contexts/NotificationContext";
import { useContext } from "react";

export const GiveFeedback = () => {
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [letterCount, setLetterCount] = useState(0);

  const { fireNotification } = useContext(NotificationContext);
  const submitHandler = () => {
    giveFeedback(feedbackMessage)
      .then(() => {
        fireNotification({
          title: "Thank you for your feedback.",
          target: NOTIFICATIONS_TARGET.everyone,
          type: NOTIFICATIONS_TYPE.success,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
        });
        setFeedbackMessage("");
      })
      .catch(() => {
        fireNotification({
          title: "An error occurred, please try again",
          target: NOTIFICATIONS_TARGET.everyone,
          type: NOTIFICATIONS_TYPE.error,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
        });
      });
  };
  return (
    <Box>
      {/* Card Header */}
      <Box bgcolor={primaryColors.base.white} marginBottom={"20px"}>
        <Box
          width={"100%"}
          height={"8px"}
          sx={{
            backgroundImage: "linear-gradient(45deg, #FFB029 0%, #FFE7BD 100%)",
          }}
        ></Box>
        <Grid
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          padding={"28px 24px"}
        >
          <Typography
            sx={{
              color: primaryColors.gray[900],
              fontSize: fontSizeVariations["display-xs"],
              fontWeight: fontWeightVariations["bold-700"],
            }}
          >
            Give feedback
          </Typography>
          <Button
            disabled={!(letterCount > 0 && letterCount < 1001)}
            onClick={submitHandler}
            sx={{
              backgroundColor:
                letterCount > 0 && letterCount < 1001
                  ? primaryColors.warning[500]
                  : primaryColors.warning[200],
              padding: "10px 16px",
              borderRadius: "8px",
            }}
          >
            <Typography
              sx={{
                textTransform: "capitalize",
                color: primaryColors.base.white,
                fontSize: fontSizeVariations["text-sm"],
                fontWeight: fontWeightVariations["medium-500"],
              }}
            >
              Submit
            </Typography>
          </Button>
        </Grid>
      </Box>
      <Box>
        <Grid container>
          {/* question */}
          <Typography
            sx={{
              color: primaryColors.gray[700],
              fontSize: fontSizeVariations["text-md"],
              fontWeight: fontWeightVariations["bold-700"],
            }}
          >
            How was your overall experience?
          </Typography>
          {/* answer */}
          <TextField
            placeholder="A few sentences is all it takes! We will be forever grateful. Thank you in advance for helping us out! "
            value={feedbackMessage}
            onChange={(event) => {
              setLetterCount(event.target.value.length);
              setFeedbackMessage(event.target.value);
            }}
            rows={10}
            multiline
            sx={{
              marginBlock: "6px",
              backgroundColor: primaryColors.base.white,
              width: "100%",
              border:
                letterCount > 1000
                  ? `1px solid ${primaryColors.error[300]}`
                  : `1px solid ${primaryColors.gray[300]}`,
              borderRadius: "8px",
              "& fieldset": { border: "none" },
            }}
          ></TextField>
          {/* letters count */}
          <Grid
            container
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignContent="flex-end"
          >
            <Typography
              sx={{
                //   position: "relative",
                //   left: "95%",
                color:
                  letterCount > 1000
                    ? primaryColors.error[500]
                    : primaryColors.gray[500],
                fontSize: fontSizeVariations["text-sm"],
                fontWeight: fontWeightVariations["medium-500"],
              }}
            >
              {letterCount > 1000 ? " Maximum characters exceeded" : ""}{" "}
              {letterCount}/1000
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
